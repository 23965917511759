<template>
  <div>
    <div class="business">
      <div class="container">
        <div class="business__content" :class="{ hide: !showContent }">
          <h1
            class="business-title"
            v-if="firstblock.first[0].title"
            v-html="firstblock.first[0].title"
          ></h1>
          <h5 class="business-par">
            <!-- {{firstblock.first[0].description}} -->
            {{ $t("par.bus1") }} <span>{{ $t("par.bus2") }}</span
            ><br />
            {{ $t("par.bus3") }}
          </h5>
          <h6
            class="business-sub"
            v-if="firstblock.first[1].description"
            v-html="firstblock.first[1].description"
          ></h6>
          <div class="business-btn">
            <button
              class="business-btn__desktop m_none"
              id="click1"
              @click="(isOpen = true), clickGA, this.$refs.myForm.resetForm()"
            >
              {{ $t("buttons.getPresentation") }}
              <!-- <h2>Получить презентацию компании</h2> -->
            </button>
            <button
              class="business-btn__mobile d_none"
              id="click1"
              @click="isOpen = true"
            >
              {{ $t("buttons.apply") }}
            </button>
          </div>
          <modal :open="isOpen" @close="isOpen = !isOpen">
            <get-presentation />
            <Form ref="myForm" @submit="Send">
            <div class="modal-inputs">
              <div class="err-message">
                <Field
                  v-model="GuestName"
                  name="username"
                  type="text"
                  class="name"
                  :placeholder="$t('placeholder.name')"
                  :rules="requiredName"
                  @keyup="onlyText()"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="username" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-model="organization_name"
                  type="text"
                  :placeholder="$t('placeholder.company')"
                  name="organization"
                  class="name"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="organization" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-mask="'+7(7##)-###-##-##'"
                  v-model="GuestPhone"
                  type="text"
                  :placeholder="$t('placeholder.phone')"
                  name="phone"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="phone" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-model="GuestEmail"
                  type="email"
                  :rules="validateEmail"
                  name="email"
                  id=""
                  :placeholder="$t('placeholder.email')"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="email" />
                </div>
              </div>
              <textarea
                v-model="guest_desc"
                :placeholder="$t('placeholder.com')"
              ></textarea>
            </div>
            <div class="modal-btn">
              <button
              type="submit"
                :class="{isDisabled : disabled}"
                @click="buttonClicked"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                ></div>
                {{ $t("buttons.send") }}
              </button>
            </div>
            <div class="modal-rule">
              <input
                class="inp-custom"
                type="checkbox"
                id="checkbox"
                v-model="checked"
              />
              <label for="checkbox"
                >{{ $t("modal.check1") }}
                <a href="/condition">{{ $t("modal.check2") }}</a></label
              >
            </div>
          </Form>
          </modal>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="container">
        <div
          class="about-title"
          v-if="firstblock.second[0].title"
          v-html="firstblock.second[0].title"
        ></div>
        <div class="about-types">
          <div class="about-types__fond">
            <img src="../images/centras-1.png" alt="" />
            <p
              v-if="firstblock.second[0].description"
              v-html="firstblock.second[0].description"
            ></p>
          </div>
          <div class="about-types__world">
            <img src="../images/centras-2.png" alt="" />
            <p
              v-if="firstblock.second[1].description"
              v-html="firstblock.second[1].description"
            ></p>
          </div>
          <div class="about-types__prod">
            <img src="../images/centras-3-1.png" alt="" />
            <p
              v-if="firstblock.second[2].description"
              v-html="firstblock.second[2].description"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import getPresentation from "@/components/getPresentation.vue";
import axios from "axios";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Buffer, resolveObjectURL } from "buffer";
import { useToast } from "vue-toastification";
const toaster = useToast();
export default {
  props: ["firstblock"],
  components: { modal, getPresentation, Field, Form, ErrorMessage },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      organization_name: null,
      resp: [],
      disabled: false,
      showContent: true,
      respo: "",
      respo1: "",
      checked: false,
      guest_desc: null,
      loading: false,
      IsRemove: false,
    };
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      setTimeout(() => (this.disabled = !true), 5000);
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      return true;
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "presentation",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          // description: "mainPage",
          product_name: "asdasd",
          description: this.guest_desc,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 3000);
              resetForm();
              this.checked = false,
              this.IsRemove = true;
              setTimeout(()=> (this.isOpen = false), 500);
              toaster.success(this.$t('notification.success'), {
                position: "top-right"
              });
            } else {
              toaster.error(this.$t('notification.req'), {
                position: "top-right"
              });
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree'), {
            position: "top-right"
          }
        );
      }
      // this.loading = !false;
      // setTimeout(() => (this.loading = !true), 3000);
    },
    clickGA() {
      this.$gtag.event("send-presentation", {
        event_category: "presentation",
        event_label: "company presentation",
        value: 1,
      });
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestEmail = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.checked = false,
      this.IsRemove = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/headMain.scss";
.isDisabled {
  opacity: 0.2;
  backdrop-filter: sepia(90%);
}
// .remove {
//   display: none;
//   margin-bottom: -15px;
// }
</style>
