<template>
  <div>
    <head-bottom />
    <div class="dgpo">
      <div class="container">
        <div class="dgpo-left">
          <p class="dgpo-left__par">
            Страхование гражданской ответственности автовладельцев
          </p>
          <p class="dgpo-left__title" v-html="gpo.title"></p>
          <p class="dgpo-left__sub" v-html="gpo.description"></p>
          <!-- <p class="dgpo-left__sub">Возместим причиненный вами вред <br> жизни, здоровью и имуществу</p> -->
          <div class="dgpo-left__btn">
            <button id="click5" @click="isOpen = true, this.$refs.myForm.resetForm()">{{ gpo.button }}</button>
            <!-- <button @click="isOpen=true">Получить индивидуальное предложение</button> -->
          </div>
          <modal :open="isOpen" @close="isOpen = !isOpen">
            <get-discount />
            <Form ref="myForm" @submit="Send">
            <div class="modal-inputs">
              <div class="err-message">
                <Field
                  v-model="GuestName"
                  name="username"
                  type="text"
                  class="name"
                  :placeholder="$t('placeholder.name')"
                  :rules="requiredName"
                  @keyup="onlyText()"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="username" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-mask="'+7(7##)-###-##-##'"
                  v-model="GuestPhone"
                  type="text"
                  :placeholder="$t('placeholder.phone')"
                  name="phone"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="phone" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-model="organization_name"
                  type="text"
                  :placeholder="$t('placeholder.company')"
                  name="organization"
                  class="name"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="organization" />
                </div>
              </div>
              <textarea
                v-model="guest_desc"
                :placeholder="$t('placeholder.com')"
              ></textarea>
            </div>
            <div class="modal-btn">
              <button
                type="submit"
                :class="{isDisabled : disabled}"
                @click="buttonClicked"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                ></div>
                {{ $t("buttons.send") }}
              </button>
            </div>
            <div class="modal-rule">
              <input type="checkbox" id="check" v-model="checked" />
              <label for="check"
                >{{ $t("modal.check1") }}
                <a href="/condition">{{ $t("modal.check2") }}</a></label
              >
            </div>
          </Form>
          </modal>
        </div>
        <div class="dgpo-right">
          <!-- <hr class="dgpo-right__line"> -->
          <div class="dgpo-right__line"></div>
          <div class="dgpo-right__card">
            <ul>
              <li v-html="gpo.mini_description_first"></li>
            </ul>
          </div>
          <div class="dgpo-right__card">
            <ul>
              <li v-html="gpo.mini_description_second"></li>
            </ul>
          </div>
          <div class="dgpo-right__card">
            <ul>
              <li v-html="gpo.mini_description_third"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <hr class="middle-line" />
      <div class="container">
        <div class="middle-box">
          <p class="middle-box__title" v-html="tabs[0].big_title"></p>
          <div class="dgpo-slider">
            <dgpo-slider />
          </div>
          <div class="middle-box__bottom">
            <div class="middle-box__bottom-1">
              <button
                v-for="t in tabs.slice(0, 3)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>

            <div class="middle-box__bottom-3">
              <button
                v-for="t in tabs.slice(3, 6)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>
            <div class="middle-box__bottom-2" v-if="tabContent">
              <img :src="cdn + tabContent.icon" alt="" />
              <div class="par" v-html="tabContent.description"></div>
              <div class="btn">
                <button
                  @click="isOpen = true, this.$refs.myForm.resetForm()"
                  v-html="tabContent.button"
                ></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
            </div>
            <div class="middle-box__bottom-2" v-else>
              <img :src="cdn + tabs[0].icon" alt="" />
              <div class="par" v-html="tabs[0].description"></div>
              <div class="btn">
                <button
                  @click="isOpen = true, this.$refs.myForm.resetForm()"
                  id="click6"
                  v-html="tabs[0].button"
                ></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="risk">
      <div class="container1">
        <div class="risk-title" v-html="gpo3[0].big_title"></div>
        <div class="risk-name">
          <div class="risk-list" v-for="(g, i) in gpo3" :key="g">
            <div class="risk-list__num" v-if="i < 10">0{{ i + 1 }}.</div>
            <div class="risk-list__num" v-else>{{ i + 1 }}.</div>
            <div class="risk-list__par">
              <span v-html="g.title"></span>
              <p v-html="g.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="range">
      <range />
    </div>
    <div class="sliderRange">
      <slider-range />
    </div>
    <div class="reverse1">
      <div class="cargo-accordion">
        <accordion />
      </div>
      <discount :p_name="p_name" />
    </div>
    <SliderMain1 />
    <client-say />
    <div class="wrap">
      <slider-main-2 />
      <div class="d_none">
        <review-slide />
      </div>
    </div>
  </div>
</template>

<script>
import Discount from "../components/Discount.vue";
import HeadBottom from "../components/HeadBottom.vue";
import SliderMain1 from "../components/SliderMain1-1.vue";
import SliderMain2 from "../components/SliderMain2.vue";
import accordion from "../components/accordion.vue";
import range from "../components/range.vue";
import sliderRange from "../components/sliderRange.vue";
import dgpoSlider from "../components/box/dgpoSlider.vue";
import reviewSlide from "../components/box/reviewSlide.vue";
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { mapState } from "vuex";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { useToast } from "vue-toastification";
const toaster = useToast()
import first from "../assets/images/kasko-img.png";
import second from "../assets/images/kasko2.png";
import third from "../assets/images/health-img.png";
import fourth from "../assets/images/dgpo-img.png";
import fifth from "../assets/images/cargo6.png";
import sixth from "../assets/images/kasko6.png";
import getDiscount from "@/components/getDiscount.vue";
import clientSay from "@/components/clientSay.vue";
// import SliderMain1 from '../components/SliderMain1.vue'

export default {
  components: {
    SliderMain1,
    SliderMain2,
    HeadBottom,
    Discount,
    accordion,
    range,
    modal,
    SliderMain1,
    sliderRange,
    dgpoSlider,
    reviewSlide,
    getDiscount,
    clientSay,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  created() {
    axios
      .get("V1/top-products")
      .then((response) => {
        this.gpo = response.data.data[3].first_block;
        this.tabs = response.data.data[3].second_block;
        this.gpo3 = response.data.data[3].third_block;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  data() {
    return {
      disabled: false,
      loading: false,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      product_name: "Обязательное страхование юр. лиц",
      p_name: "Обязательное страхование юр. лиц",
      tabContent: null,
      resp: [],
      tab: "",
      checked: false,
      organization_name: null,
      guest_desc: null,
      gpo: [],
      // gpo2: [],
      gpo3: [],
      IsRemove: false,
      tabs: [
        {
          title: "Защита",
          img: first,
          text: "Дополнительная финансовая защита к вашей обязательной автостраховке",
        },
        {
          title: "Выплаты",
          img: second,
          text: "До исчерпания страховой суммы, сроком от 1 до 12 месяцев",
        },
        {
          title: "24/7",
          img: third,
          text: "Личный менеджер 24/7",
        },
        {
          title: "Тарифы",
          img: fourth,
          text: "Гибкие условия по приемлемым тарифам",
        },
        {
          title: "Страховой случай",
          img: fifth,
          text: "Выезд уполномоченного представителя страховщика при наступлении страхового случая",
        },
        {
          title: "Филиалы",
          img: sixth,
          text: "Филиалы в 15 регионах Казахстана",
        },
      ],
    };
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    tabButton(t) {
      if (t == null) {
        // console.log("test", this.tabs[0]);
        this.tabs[0] = t;
      } else {
        this.tabContent = t;
        // console.log("tab", t);
      }
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "consultant",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          description: this.guest_desc,
          product_name: this.product_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 20000);
              toaster.success(this.$t('notification.success'), {
                  position: "top-right"
                });
              resetForm()
              setTimeout(() => {
                this.isOpen = false;
              }, 500);
            } else {
              toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
      }
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.checked = false;
      this.IsRemove = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background: #ffffff;
}
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1023px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1023px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.time-sub {
  @include mobile() {
    padding-top: 7px;
    padding-bottom: 15px;
  }
}
.container {
  display: flex;
  justify-content: space-between;
}
.container1 {
  margin: 0 auto;
  max-width: 1320px;
  width: 78%;
  @include mobile() {
    width: 90%;
  }
  @include tablet() {
    width: 90%;
  }
}
.dgpo {
  padding-bottom: 272px;
  background: url(../assets/images/dgpo-back.png);
  background-repeat: no-repeat;
  background-size: auto;
  &-slider {
    display: none;
    @include mobile() {
      display: block;
    }
    @include tablet() {
      display: block;
    }
    @include tablet-1024() {
      display: block;
    }
  }
  &-left {
    &__par {
      @include fontStyle(Roboto, normal, 400, 22px, 30px, #373737);
      letter-spacing: 0.015em;
      text-transform: uppercase;
      padding-top: 95px;
    }
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 49px, #141414);
      text-transform: uppercase;
    }
    &__sub {
      padding-top: 20px;
      padding-bottom: 50px;
      @include fontStyle(Roboto, normal, 400, 20px, 23px, #373737);
      letter-spacing: 0.01em;
      text-shadow: 0px 4px 50px rgba(0, 0, 0, 0.45);
    }
    &__btn {
      width: 405px;
      height: 70px;
      background: #ffffff;
      border-radius: 15px;
      margin-top: 50px;
      margin-bottom: 120px;
      button {
        width: 405px;
        height: 70px;
        background: #ffffff;
        border-radius: 15px;
        border: none;
        @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #ffffff;
          button {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            background-clip: text;
          }
        }
      }
    }
  }
  &-right {
    margin-top: 170px;
    margin-left: 70px;
    &__line {
      border-left: 2px solid #ffffff;
      position: absolute;
      height: 410px;
      margin-left: 9px;
      margin-top: -44px;
    }
    &__card {
      margin-bottom: 35px;
      width: 340px;
      height: 90px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.28);
      backdrop-filter: blur(20px);
      border-radius: 15px;
      z-index: 1;
      ul {
        padding: 26px 0 33px 45px;
        li {
          @include fontStyle(Roboto, normal, 400, 17px, 23px, #ffffff);
          text-shadow: 0px 4px 43px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
  &-accordion {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  @include mobile() {
    background: url("../images/dgpo-back22.png");
    background-size: cover;
    padding-bottom: 50px;
    height: 378px;
    &-right {
      display: none;
    }
    &-left {
      width: 100%;
      &__par {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        padding: 35px 0 26px 0;
        width: 80%;
      }
      &__title {
        font-size: 12px;
        line-height: 14px;
        text-transform: none;
        background: #a3b3ae;
        backdrop-filter: blur(30px);
        border-radius: 0px 5px 5px 0px;
        padding: 10px 10px 10px 6%;
        width: 88%;
        margin-left: -6%;
      }
      &__sub {
        display: none;
      }
      &__btn {
        width: 100%;
        height: 0px;
        margin: 0 auto;
        button {
          margin-top: 21%;
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          left: 16x;
        }
      }
    }
  }
  @include tablet() {
    background-size: cover;
    padding-bottom: 20px;
    &-right {
      display: none;
    }
    &-left {
      width: 100%;
      &__par {
        font-size: 10px;
        line-height: 12px;
        padding-top: 30px;
      }
      &__title {
        font-size: 12px;
        line-height: 14px;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      &__sub {
        display: none;
      }
      &__btn {
        min-width: 220px;
        max-width: 260px;
        height: 0px;
        border-radius: 8px;
        // margin-top: 5px;
        // margin-bottom: 20px;
        margin: 0 auto;
        button {
          min-width: 220px;
          max-width: 260px;
          height: 38px;
          border-radius: 3px;
          font-size: 12px;
          left: 14px;
        }
      }
    }
  }
  @include tablet-1024() {
    background-size: cover;
    padding-bottom: 0;
    &-right {
      display: none;
    }
    &-left {
      width: 100%;
      &__par {
        font-size: 13px;
        line-height: 16px;
        padding-top: 30px;
      }
      &__title {
        font-size: 14px;
        line-height: 16px;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      &__sub {
        display: none;
      }
      &__btn {
        margin: 0 auto;
        width: 200px;
        height: 30px;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 20px;
        // margin: 0 auto;
        button {
          width: 200px;
          height: 30px;
          border-radius: 8px;
          font-size: 9px;
          left: 12px;
        }
      }
    }
  }
  @include desktop() {
    background-size: contain;
    padding-bottom: 70px;
    &-left {
      &__par {
        padding-top: 40px;
        font-size: 13px;
        line-height: 16px;
      }
      &__title {
        font-size: 18px;
        line-height: 22px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      &__sub {
        font-size: 12px;
        line-height: 15px;
        padding-bottom: 0;
        padding-top: 10px;
      }
      &__btn {
        width: 300px;
        height: 50px;
        border-radius: 8px;
        button {
          width: 300px;
          height: 50px;
          border-radius: 8px;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
    &-right {
      margin-top: 40px;
      &__card {
        width: 300px;
        height: 60px;
        margin-bottom: 15px;
        ul {
          padding: 10px 20px;
          li {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }
  @include desktop-max() {
    &-right {
      &__line {
        height: 670px;
        margin-left: 32px;
        margin-top: -173px;
      }
    }
  }
}
.middle {
  &-line {
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    height: 1px;
    margin-top: -170px;
    position: relative;
    z-index: 3;
    top: 70px;
  }
  &-box {
    z-index: 2;
    background-color: #f6f6f6;
    padding: 100px 100px;
    border-radius: 15px;
    margin-top: -150px;
    max-width: 1320px;
    width: 100%;
    &__title {
      @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      &-1 {
        text-align: center;
      }
      &-1,
      &-3 {
        max-width: 300px;
        flex-wrap: wrap;
        button {
          background: #f6f6f6;
          width: 190px;
          height: 60px;
          border: 1px solid #c4c4c4;
          border-radius: 15px;
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
          margin-top: 40px;
          &:hover {
            filter: drop-shadow(0px 4px 43px rgba(0, 0, 0, 0.15));
            background-color: #fff;
            border: none;
          }
        }
      }
      &-2 {
        text-align: center;
        height: 350px;
        img {
          max-width: 106px;
          max-height: 106px;
        }
        .par {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, transparent);
          background: #373737;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          padding-top: 20px;
          padding-bottom: 130px;
          text-align: center;
          margin: 0 auto;
          width: 270px;
          height: 200px;
        }
        .btn {
          width: 380px;
          height: 70px;
          background: #ffffff;
          border-radius: 15px;
          button {
            padding-top: 15px;
            background: #ffffff;
            border: none;
            @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
          }
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
            button {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #ffffff;
              background-clip: text;
            }
          }
        }
      }
      &-3 {
        text-align: center;
      }
    }
  }
  @include mobile() {
    padding-top: 15px;
    padding-bottom: 20px;
    background: #efefef;
    background-size: cover;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0;
      background: transparent;
      padding: 0;
      &__title {
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 10px;
        padding-top: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet() {
    background: #efefef;
    background-size: cover;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0;
      background: transparent;
      padding: 0;
      &__title {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 10px;
        padding-top: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet-1024() {
    background: #f6f6f6;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0px;
      padding: 50px 70px;
      &__title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include desktop() {
    &-line {
      margin-top: -60px;
      height: 1px;
    }
    &-box {
      margin-top: -80px;
      padding: 50px;
      &__title {
        font-size: 22px;
        line-height: 26px;
      }
      &__bottom {
        &-2 {
          width: 100%;
          padding-left: 10px;
          .btn {
            width: 280px;
            height: 60px;
            border-radius: 10px;
            button {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.risk {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-top: 90px;
    margin-bottom: 30px;
  }
  &-list {
    display: flex;
    align-items: center;
    margin-top: 40px;
    &__num {
      @include fontStyle(Roboto, normal, 900, 60px, 82px, transparent);
      background: -webkit-linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-stroke: 2px transparent;
      color: #ffffff;
      align-items: center;
    }
    &__par {
      @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
      background: #f6f6f6;
      border: 1px solid rgba(255, 255, 255, 0.05);
      box-sizing: border-box;
      backdrop-filter: blur(55px);
      border-radius: 15px;
      padding: 20px;
      margin-left: 12px;
      width: 1220px;
      height: 70px;
      display: flex;
      align-items: baseline;
      span {
        @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
        padding-right: 20px;
      }
    }
    span {
      @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
      padding-right: 20px;
    }
  }
  @include mobile() {
    width: 90%;
    margin-top: 35px;
    margin-bottom: -10px;
    &-title {
      //change
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;
      background: linear-gradient(
        90deg,
        #ff143f 3%,
        #c600dd 25.97%,
        #1b27ff 31%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
      margin-top: 0;
      margin-bottom: 0;
    }
    &-list {
      align-items: baseline;
      margin-top: 0px;
      margin-bottom: 10px;
      &__num {
        //change
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        letter-spacing: 0.03em;
        padding-left: 4px;
      }
      &__par {
        display: flex;
        flex-wrap: wrap;
        //change
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        background: transparent;
        margin-left: 5px;
        padding: 0;
        span {
          width: 100%;
          //change
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          padding-right: 10px;
        }
      }
    }
  }
  @include tablet() {
    margin-top: 45px;
    margin-bottom: 40px;
    &-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      background: linear-gradient(
        90deg,
        #ff143f 3%,
        #c600dd 25.97%,
        #1b27ff 31%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
      margin-top: 0;
      // margin-bottom: -20px;
    }
    &-list {
      align-items: baseline;
      margin-top: 20px;
      &__num {
        font-size: 15px;
        line-height: 22px;
        font-weight: 700;
        letter-spacing: 0.03em;
        padding-left: 4px;
      }
      &__par {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        background: transparent;
        margin-left: 5px;
        padding: 0;
        span {
          width: 100%;
          font-size: 14px;
          line-height: 15px;
          font-weight: 500;
          padding-right: 10px;
        }
      }
    }
  }
  @include tablet-1024() {
    margin-top: 45px;
    margin-bottom: 40px;
    &-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
      margin-top: 0;
      margin-bottom: -20px;
    }
    &-list {
      align-items: baseline;
      &__num {
        font-size: 22px;
        line-height: 28px;
        font-weight: 700;
        letter-spacing: 0.03em;
        padding-left: 4px;
      }
      &__par {
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        background: #f6f6f6;
        padding: 10px !important;
        margin-left: 5px;
        padding: 0;
        span {
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          padding-right: 10px;
        }
      }
    }
  }
  @include desktop-max() {
    &-list {
      margin-top: 15px;
      &__par {
        height: 80px;
      }
    }
  }
}
.slide {
  &-top,
  &-btm {
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
      text-transform: uppercase;
      padding-bottom: 35px;
      padding-top: 100px;
      span {
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
  &-btm {
    background: #efefef;
    padding-bottom: 70px;
    p {
      text-align: center;
    }
  }
}
</style>
