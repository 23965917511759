<template>
  <div class="form">
    <div class="container">
      <div class="form-name">
        <Form class="err-message">
          <Field
            @click="IsRemove = false"
            v-model="name"
            name="name"
            type="text"
            class="name"
            :placeholder="$t('placeholder.name')"
            :rules="requiredName"
            @keyup="onlyText()"
          />
          <div :class="{ remove: IsRemove }" class="">
            <ErrorMessage name="name" />
          </div>
        </Form>
      </div>
      <div class="form-tel">
        <Form class="err-message">
          <Field
            @click="IsRemove = false"
            v-mask="'+7(7##)-###-##-##'"
            v-model="phone"
            :placeholder="$t('placeholder.phone')"
            type="text"
            name="phone"
            class="tel"
            :rules="requiredNum"
          />
          <div :class="{ remove: IsRemove }" class="">
            <ErrorMessage name="phone" />
          </div>
        </Form>
      </div>
      <div class="select-text">{{ $t("placeholder.choose") }}</div>
      <select v-model="selected" class="modal-vacancy">
        <option
          v-for="v in vac"
          :key="v.id"
          :value="v"
          v-html="v.title"
        ></option>
      </select>
      <br />
      <div class="modal-res">
        {{ $t("modal.vacancy") }}
        <span><img src="../assets/images/resume.png" alt="" /></span>
        <!-- <img src="../assets/images/resume.png" alt=""> -->
        <input type="file" id="file" ref="file" @change="handleFileUpload()" />
        <p class="file-name" v-if="file">{{ $t("par.chooseFile") }}</p>
      </div>
      <div class="form-btn">
        <button
          @click.prevent="submitFile"
          :disabled="disabled"
          :class="{isDisabled : disabled}"
          @click="buttonClicked"
        >
          <div v-if="loading" class="spinner-border spinner-border-sm"></div>
          {{ $t("buttons.offer") }}
        </button>
      </div>
      <div class="modal-rule">
        <input type="checkbox" id="check" v-model="checked" />
        <label for="check"
          >{{ $t("modal.check1") }}
          <a href="/condition">{{ $t("modal.check2") }}</a></label
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import { useToast } from "vue-toastification";
import { Field, Form, ErrorMessage } from "vee-validate";
const toaster = useToast();
export default {
  components: { Field, Form, ErrorMessage },
  data() {
    return {
      name: null,
      phone: null,
      GuestEmail: null,
      tabContent: null,
      checked: false,
      disabled: false,
      loading: false,
      selected: "Выберите один из вариантов",
      vac: [],
      IsRemove: false,
    };
  },
  created() {
    axios
      .get("V1/vacancies")
      .then((response) => {
        this.vac = response.data.data;
        // this.tabContent = response.data.info.third_block.body
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    onlyText() {
      this.name = this.name.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    submitFile() {
      if (this.checked === true) {
        let formData = new FormData();
        formData.append("vacancy_id", "1");
        formData.append("name", this.name);
        formData.append("phone", this.phone);
        formData.append("vacancy_id", this.selected.id);
        formData.append("file", this.file);
        axios
          .post("V1/response", formData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 3000);
              toaster.success(
                this.$t("notification.successVacancy"),
                {
                  position: "top-right"
                }
              );
              this.name = "";
              this.phone = "";
              this.file = null;
              this.vac.title = null;
              this.selected = this.$t("placeholder.choose");
              this.checked = false,
              this.$refs.file.value = null
              this.IsRemove = true;
            } else {
              // console.log('asdasdasdasd',res.data)
              toaster.error(this.$t("notification.req"), {
                position: "top-right"
              });
              this.IsRemove = false;
            }
          })
          .catch((err) => {
            console.log(err);
            // console.log(auth);
            toaster.error(this.$t("notification.req"), {
              position: "top-right"
            });
            this.IsRemove = false;
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
        this.IsRemove = false;
      }
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const checkForNumbers = /^[а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z]+$/i;
      if (!checkForNumbers.test(value)) {
        return "Неправильно введённые данные!";
      }
      //All is good
      return true;
    },
    requiredNum(valueNum) {
      if (!valueNum || valueNum.length < 17) {
        this.disabled = !false
        return this.$t("placeholder.req");
      }
      this.disabled = false
      return true;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log("asdasd", this.$refs.file.files[0]);
    },
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>
<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1440px) and (max-width: 2560px) {
    @content;
  }
}
.select-text {
  @include fontStyle(Roboto, normal, 300, 14px, 16px, #959595);
  padding-bottom: 3px;
}
.modal {
  &-res {
    min-width: 165px;
    width: 100%;
    margin: 0 auto;
    #file {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      opacity: 0;
      margin-top: -30px;
    }
  }
  &-vacancy {
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    }
}
select {
  appearance: none;
-webkit-appearance: none;
}
.container {
  @include mobile() {
    text-align: center;
  }
  @include tablet() {
    text-align: center;
  }
}
.remove {
  display: none;
  margin-bottom: -15px;
}
.form {
  background-color: #efefef;
  padding-top: 30px;
  padding-bottom: 30px;
  &-name,
  &-tel,
  &-vacancy {
    margin-bottom: 10px;
    input {
      min-width: 260px;
      max-width: 300px;
      height: 38px;
      background: #ffffff;
      border-radius: 3px;
      border: none;
      @include fontStyle(Roboto, normal, 300, 14px, 16px, #959595);
      padding: 5px 0px 5px 10px;
    }
  }
  &-btn {
    button {
      width: 165px;
      height: 32px;
      background-color: #fff;
      border-radius: 3px;
      border: none;
      @include fontStyle(Roboto, normal, 600, 12px, 14px, #8948b1);
      &:hover {
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        color: #ffffff;
      }
    }
  }
  @include tablet-1024() {
    display: none;
  }
  @include desktop() {
    display: none;
  }
  @include desktop-max() {
    display: none;
  }
}
</style>
