<template>
    <swiper 
            :effect="'coverflow'"
            :grabCursor="true"
            :centeredSlides="false"
            :slidesPerView="3"
            :coverflowEffect="{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
            }"
            :navigation="navigation"
            :modules="modules"
            class="mySwiper">
        <swiper-slide v-for="v in video" :key="v">
            <iframe width="560" height="315" :src="v.video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <!-- <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/3qvVHVcPWqw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide> -->
        <!-- <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/NGd9iogZVZE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/vzh_NisTSeQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/9mtWLY6ZhPc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/VTZzFSGc8XU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ESOidRBE01c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/gawX7EVz5ro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/BO2YW2URw6s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide> -->
        <div class="nav-btn">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </swiper>
</template>


<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/effect-coverflow";
import "swiper/css";
import "swiper/css/navigation";
import {EffectCoverflow, Navigation } from "swiper";
import axios from 'axios';
import {mapState} from 'vuex';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            video: [],
        }
    },
    created(){
        axios.get('V1/company-videos')
            .then(response => {
                this.video = response.data.data
            })
            .catch(e => {
                this.errors.push(e)
            })
    },
    computed :{
        ...mapState(['cdn']),
    },
    setup() {
        return {
            modules: [EffectCoverflow,Navigation],
        };
    },
};
</script>

<style lang="scss" scoped>
.mySwiper__img{
    padding: 30px;
}
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    iframe {
        @include mobile() {
            height: 130px;
        }
        @include tablet() {
            height: 200px;
        }
    }
    .swiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        &-button {
            &-prev, &-next {
                color: #c4c4c4;
            }
        }
        @include mobile() {
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 370px;
        height: 300px;
        @include mobile() {
            height: 125px;
        }
        @include tablet() {
            height: 200px;
        }
    }
    .swiper-slide iframe {
        display: block;
        width: 100%;
    }


    // .swiper {
    //     &-button {
    //         &-next, &-prev {
    //             --swiper-theme-color: #ffffff;
    //             // background-image: url(../assets/images/left.png);
    //             // background-repeat: no-repeat;
    //             // background-size: 100% auto;
    //             // background-position: center;
    //             // &::after {
    //             //     display: none;
    //             // }
    //         }
    //     }
    // }
    // .mySwiper {
    //     &__img {
    //         width: 100%;
    //     }
    //     @include mobile() {
    //         img {
    //             width: 70%;
    //             margin-left: 40px;
    //         }
    //     }
    //     @include tablet() {
    //         img {
    //             width: 80%;
    //             display: flex;
    //             justify-content: center;
    //             margin-left: 40px;
    //             margin-right: 30px;
    //         }
    //     }
    // }
</style>