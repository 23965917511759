<template>
    <section class="bcc">
        <div class="container">
            <Form class="modal-inputs" ref="myForm">
                <div class="err-message">
                    <Field v-model="objectType" class="modal-vacancy" name="objectType" as="select" :rules="requiredField">
                        <option value="" disabled>Тип объекта</option>
                        <option value="Легковые транспортные средства">Легковые транспортные средства</option>
                        <option value="Грузовые транспортные средства">Грузовые транспортные средства</option>
                        <option value="Спец.Техники">Спец.Техники</option>
                        <option value="Страхование медицинского оборудования">Страхование медицинского оборудования</option>
                        <option value="Страхование ЖД">Страхование ЖД</option>
                        <option value="Жилые дома и квартиры">Жилые дома и квартиры</option>
                        <option value="Прицепы">Прицепы</option>
                    </Field>
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="objectType" />
                    </div>
                </div>
                

                <div v-if="objectType === 'Жилые дома и квартиры'" class="err-message">
                    <Field v-model="partnerType" class="modal-vacancy" name="partnerType" as="select" :rules="requiredField">
                        <option value="" disabled>Тип объекта жилые дома и квартиры</option>
                        <option value="Офис/магазин">Офис/магазин</option>
                        <option value="Кафе/ресторан">Кафе/ресторан</option>
                    </Field>
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="objectType" />
                    </div>
                </div>

                <div v-if="objectType === 'Страхование ЖД'" class="err-message">
                    <Field v-model="railwayType" class="modal-vacancy" name="railwayType" as="select" :rules="requiredField">
                        <option value="" disabled>Тип железнодорожного транспорта</option>
                        <option value="Тяговые транспортные средства (локомотивы)">Тяговые транспортные средства (локомотивы)</option>
                        <option value="Пассажирский вагонный состав">Пассажирский вагонный состав</option>
                        <option value="Вагоны-автомобилевозы, бункерные, вагоны-лесовозы, вагоны-цистерны, думпкары, комбинированные вагоны, полувагоны, крытые вагоны платформы, фитинговые платформы, хопперы, вагоны промышленного транспорта, чугуновозы, шлаковозы, вагонетки, вагон для торфа">Вагоны-автомобилевозы, бункерные, вагоны-лесовозы, вагоны-цистерны, думпкары, комбинированные вагоны, полувагоны, крытые вагоны платформы, фитинговые платформы, хопперы, вагоны промышленного транспорта, чугуновозы, шлаковозы, вагонетки, вагон для торфа</option>
                        <option value="Грузовые изотермические (вагоны-ледники, вагон-термос, рефрижераторные вагоны)">Грузовые изотермические (вагоны-ледники, вагон-термос, рефрижераторные вагоны)</option>
                        <option value="Вагоны специального назначения (багажные, почтовые, вагоны рестораны и т.д.)">Вагоны специального назначения (багажные, почтовые, вагоны рестораны и т.д.)</option>
                    </Field>
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="objectType" />
                    </div>
                </div>
                
                <div class="err-message">
                    <Field
                        v-model="actualValue"
                        name="actualValue"
                        type="text"
                        placeholder="Действительная стоимость объекта"
                        :rules="requiredField"
                        v-mask="'###############'"
                    />
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="actualValue" />
                    </div>
                </div>

                <div class="err-message">
                    <Field
                        v-model="insuranceSum"
                        name="insuranceSum"
                        type="text"
                        placeholder="Страховая сумма"
                        :rules="requiredField"
                        mask="'###############'"
                    />
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="insuranceSum" />
                    </div>
                </div>

                <div class="err-message">
                    <Field
                        v-model="email"
                        name="email"
                        type="email"
                        placeholder="Почта"
                        :rules="validateEmail"
                    />
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="email" />
                    </div>
                </div>

                <div class="err-message">
                    <Field
                        v-model="phone"
                        name="phone"
                        type="text"
                        placeholder="Номер телефона"
                        v-mask="'+7(7##)-###-##-##'"
                        v-validate="'min:9'"
                        :rules="requiredField"
                    />
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="phone" />
                    </div>
                </div>

                <div class="err-message">
                    <Field
                        v-model="name"
                        name="name"
                        type="text"
                        placeholder="Имя"
                        :rules="requiredField"
                    />
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="name" />
                    </div>
                </div>

                <div class="err-message">
                    <Field
                        v-model="surname"
                        name="surname"
                        type="text"
                        placeholder="Фамилия"
                        :rules="requiredField"
                    />
                    <div :class="{ remove: isRemove }" class="">
                        <ErrorMessage name="surname" />
                    </div>
                </div>

                <div v-if="!result" class="modal-btn">
                    <button
                        :class="{isDisabled : disabled}"
                        @click.prevent="validateFields(false)"
                    >
                        <div
                            v-if="loading"
                            class="spinner-border spinner-border-sm"
                        ></div>
                        Рассчитать
                    </button>
                </div>

                <div v-if="result" class="bcc__result">
                    <span><b>Страховая премия:</b> {{ result.insurancePremium }} тг</span>
                    <span><b>Безусловная франшиза:</b> {{ result.deductibleDamage }} тг</span>
                    <span><b>При повреждении/ ущербе <br>
                            При утрате и (или) полной гибели</b> {{ result.deductibleLoss }} тг</span>
                </div>

                <div v-if="result" class="modal-btn">
                    <button
                        :class="{isDisabled : disabled}"
                        @click.prevent="validateFields(true)"
                    >
                        <div
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        ></div>
                        Отправить заявку
                    </button>
                </div>
            </Form>
        </div>
    </section>
</template>
  
<script>
import { reactive, ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import axios from "axios";
import { useToast } from "vue-toastification";
const toaster = useToast()
export default {
    name: "BccPage",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup() {
        const objectType = ref("");
        const partnerType = ref("");
        const railwayType = ref("");
        const actualValue = ref("");
        const insuranceSum = ref("");
        const result = reactive(null);
        return { objectType, partnerType, railwayType, actualValue, insuranceSum, result };
    },
    data() {
        return {
            isRemove: false,
            disabled: false,
            loading: false,
            email: "",
            phone: "",
            name: "",
            surname: "",
        }
    },
    watch: {
        objectType: function() {
            console.log(this.objectType)
            this.resetResult();
        },
        partnerType: function() {
            this.resetResult();
        },
        railwayType: function() {
            this.resetResult();
        },
        actualValue: function() {
            this.resetResult();
        },
        insuranceSum: function() {
            this.resetResult();
        },
    },
    methods: {
        resetResult() {
            this.result = null;
        },
        async validateFields(sendMail) {
            const form = this.$refs.myForm;
            const isValid = await form.validate();

            if (!isValid.valid) {
                toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
                return;
            } else {
                this.sendRequest(sendMail);
            }
        },
        sendRequest(sendMail) {
            this.loading = true;
            const data = {
                "partner_id": 12,
                "signature": "qwerty123",
                "company": "centras",
                "insuranceSum": this.insuranceSum,
                "objectType": this.objectType,
                "railwayType": this.railwayType,
                "partnerType": this.partnerType,
                "actualValue": this.actualValue,
                "email": this.email,
                "phone": this.phone,
                "name": this.name,
                "surname": this.surname,
                "sendMail": sendMail
            }
            if (axios.defaults.params) {
                delete axios.defaults.params.lang;
            }
            axios.post("V1/calculate-bck", data, {
                headers: { "Content-type": "application/json" },
                params: {}
            }).then((res) => {
                if (res.status === 200) {
                    if (sendMail) {
                        toaster.success(this.$t('notification.success'), {
                            position: "top-right"
                        });
                        this.resetForm();
                    } else {
                        this.result = res.data;
                    }
                } else {
                    toaster.error(this.$t('notification.req'), {
                        position: "top-right"
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
        },
        requiredField(value) {
            if (!value) {
                return this.$t("placeholder.req");
            }
            return true;
        },
        validateEmail(value) {
            if (!value) {
                return this.$t("placeholder.req");
            }
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return this.$t("placeholder.reqEmail");
            }
            return true;
        },
        resetForm() {
            this.result = null;

            this.$refs.myForm.resetForm();
        }
    },
};
</script>
  
<style lang="scss" scoped>
.bcc {
    margin-top: 100px;
    input, select {
        font-size: 16px;
    }

    select {
        max-width: 389px;
        option {
            white-space: normal;
            
            overflow: hidden;

        }
    }

    .modal-btn button {
        cursor: pointer;
        font-size: 16px;
    }

    &__result {
        border-radius: 15px;
        width: clamp(200px, 80%, 389px);
        margin: 0 auto;
        padding: 20px;
        font-size: 18px;

        span {
            display: block;
            text-align: start;
        }
    }

    @media (min-width: 320px) and (max-width: 425px) {
        .modal-vacancy {
            width: clamp(260px, 80%, 389px);
            background: #efefef;
            margin-top: 10px;
        }
    }
    @media (min-width: 425px) and (max-width: 768px) {
        .modal-vacancy {
            width: clamp(260px, 80%, 389px);
            background: #efefef;
            margin-top: 10px;
        }
    }
}
</style>