<template>
  <div class="button">
    <button @click="isOpen = true, this.$refs.myForm.resetForm()" id="click3" class="polis-btn">
      {{ $t("buttons.polis") }}
    </button>
  </div>
  <modal :p_name="p_name" :open="isOpen" @close="isOpen = !isOpen">
    <get-discount />
    <Form ref="myForm" @submit="Send">
    <div class="modal-inputs">
      <div class="err-message">
        <Field
          v-model="GuestName"
          name="username"
          type="text"
          class="name"
          :placeholder="$t('placeholder.name')"
          :rules="requiredName"
          @keyup="onlyText()"
        />
        <div :class="{ remove: IsRemove }" class="">
          <ErrorMessage name="username" />
        </div>
      </div>
      <div class="err-message">
        <Field
          v-mask="'+7(7##)-###-##-##'"
          v-model="GuestPhone"
          type="text"
          name="phone"
          :placeholder="$t('placeholder.phone')"
          :rules="requiredNum"
        />
        <div :class="{ remove: IsRemove }" class="">
          <ErrorMessage name="phone" />
        </div>
      </div>
      <div class="err-message">
        <Field
          v-model="organization_name"
          type="text"
          :placeholder="$t('placeholder.company')"
          name="organization"
          :rules="requiredNum"
        />
        <div :class="{ remove: IsRemove }" class="">
          <ErrorMessage name="organization" />
        </div>
      </div>
      <textarea
        v-model="guest_desc"
        :placeholder="$t('placeholder.com')"
      ></textarea>
    </div>
    <div class="modal-btn">
      <button :class="{isDisabled : disabled}" @click="buttonClicked">
        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
        {{ $t("buttons.send") }}
      </button>
    </div>
    <div class="modal-rule">
      <input type="checkbox" id="check" v-model="checked" />
      <label for="check"
        >{{ $t("modal.check1") }}
        <a href="/condition">{{ $t("modal.check2") }}</a></label
      >
    </div>
  </Form>
  </modal>
</template>

<script>
import getDiscount from "./getDiscount.vue";
import axios from "axios";
import { ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import modal from "../components/windows/modal.vue";
import { Buffer, resolveObjectURL } from "buffer";
import { useToast } from "vue-toastification";
const toaster = useToast()
export default {
  components: { getDiscount, modal, Field, Form, ErrorMessage },
  props: ["p_name"],
  data() {
    return {
      loading: false,
      disabled: false,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      organization_name: null,
      guest_desc: null,
      checked: false,
      IsRemove: false,
      // product_name: "Обязательное страхование юр. лиц",
    };
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      return true;
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "discount",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          // email: this.GuestEmail,
          description: this.guest_desc,
          product_name: this.p_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 3000);
              toaster.success(this.$t('notification.success'), {
                  position: "top-right"
                });
              resetForm();
              setTimeout(()=> (this.isOpen = false),500);
            } else {
              // console.log('asdasdasdasd',res.data)
              toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
      }
    },
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      // this.GuestEmail = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.checked = false;
      this.IsRemove = true;
      // this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.err-message {
  display: block;
  span {
    @include fontStyle(Roboto, normal, 400, 16px, 20px, red);
    padding-top: 5px;
  }
  @include tablet-1024() {
    span {
      font-size: 8px;
      line-height: 12px;
    }
  }
  @include desktop() {
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.button {
  @include tablet() {
    display: flex;
    justify-content: center;
  }
}
.polis-btn {
  background: #ffffff;
  border-radius: 15px;
  width: 200px;
  height: 70px;
  border: none;
  color: #373737;
  @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
  &:hover {
    background: linear-gradient(
      90deg,
      #ff4466 0%,
      #be31ce 49.97%,
      #3641ff 100%
    );
    color: #ffffff;
  }
}
@include mobile() {
  .polis-btn {
    border-radius: 5px;
    width: 130px;
    height: 34px;
    font-size: 12px;
  }
}
@include tablet() {
  .polis-btn {
    margin: 0 auto;
    border-radius: 5px;
    width: 130px;
    height: 34px;
    font-size: 12px;
  }
}
@include tablet-1024() {
  .polis-btn {
    width: 170px;
    height: 50px;
    border-radius: 7px;
    font-size: 14px;
  }
}
</style>
