<template>
  <about-us-header/>
  <jobBack />
  <div class="vac">
    <div class="container">
      <div class="vacancy">
        <p class="vacancy-title" v-html="four.title"></p>
        <p class="vacancy-sub" v-html="four.description"></p>
        <div class="vacancy-slider2">
          <vacancySlider2 />
        </div>
        <div class="vacancy-top">
          <div
            class="vacancy-top__card"
            v-for="job in vac"
            :key="job"
          >
            <div class="vacancy-top__card__left">
              <h4 v-html="job.title"></h4>
              <h4 v-html="job.salary"></h4>
              <p v-html="job.description"></p>
            </div>
            <div class="vacancy-top__card__right">
              <!-- <p>{{job.created_at}}</p> -->
              <p>{{ format_date(job.created_at) }}</p>
              <div class="btn">
                <button>
                  <a :href="job.link">{{ $t("buttons.moreBtn") }}</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="middle animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <hr class="middle-line" />
    <div class="container">
      <div class="middle-box">
        <p class="middle-box__title" v-html="three.big_title"></p>
        <div class="vacancy-slider">
          <vacancy-slider />
        </div>
        <div class="middle-box__bottom">
          <div class="middle-box__bottom-1">
            <button
              v-for="t in tabs.slice(0, 3)"
              :value="t"
              :key="t"
              @click="tabButton(t)"
              v-html="t.title"
            ></button>
          </div>

          <div class="middle-box__bottom-3">
            <button
              v-for="t in tabs.slice(3, 6)"
              :value="t"
              :key="t"
              @click="tabButton(t)"
              v-html="t.title"
            ></button>
          </div>
          <div class="middle-box__bottom-2" v-if="tabContent">
            <img :src="cdn + tabContent.icon" alt="" />
            <div class="par" v-html="tabContent.description"></div>
            <div class="btn">
              <button @click="isOpen = true, this.$refs.myForm.resetForm()">
                {{ $t("buttons.get") }}
              </button>
            </div>
            <modal :open="isOpen" @close="isOpen = !isOpen">
              <get-discount />
              <Form ref="myForm" @submit="Send">
              <div class="modal-inputs">
                <div class="err-message">
                  <Field
                    v-model="GuestName"
                    name="username"
                    type="text"
                    class="name"
                    :placeholder="$t('placeholder.name')"
                    :rules="requiredName"
                    @keyup="onlyText()"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="username" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="GuestPhone"
                    type="text"
                    :placeholder="$t('placeholder.phone')"
                    v-mask="'+7(7##)-###-##-##'"
                    name="phone"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="phone" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="organization_name"
                    type="text"
                    :placeholder="$t('placeholder.company')"
                    name="organization"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="organization" />
                  </div>
                </div>
                <textarea
                  v-model="guest_desc"
                  :placeholder="$t('placeholder.com')"
                ></textarea>
              </div>
              <div class="modal-btn">
                <button
                  type="submit"
                  :class="{isDisabled : disabled}"
                  @click="buttonClicked"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  {{ $t("buttons.send") }}
                </button>
              </div>
              <div class="modal-rule">
                <input type="checkbox" id="check" v-model="checked" />
                <label for="check"
                  >{{ $t("modal.check1") }}
                  <a href="/condition">{{ $t("modal.check2") }}</a></label
                >
              </div>
            </Form>
            </modal>
          </div>
          <div class="middle-box__bottom-2" v-else>
            <img :src="cdn + tabs[0].icon" alt="" />
            <div class="par" v-html="tabs[0].description"></div>
            <div class="btn">
              <button @click="isOpen = true, this.$refs.myForm.resetForm()">
                {{ $t("buttons.get") }}
              </button>
            </div>
            <modal :open="isOpen" @close="isOpen = !isOpen">
              <get-discount />
              <div class="modal-inputs">
                <Form class="err-message">
                  <Field
                    v-model="GuestName"
                    name="username"
                    type="text"
                    class="name"
                    :placeholder="$t('placeholder.name')"
                    :rules="requiredName"
                    @keyup="onlyText()"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="username" />
                  </div>
                </Form>
                <Form class="err-message">
                  <Field
                    v-model="GuestPhone"
                    type="text"
                    :placeholder="$t('placeholder.phone')"
                    v-mask="'+7(7##)-###-##-##'"
                    name="phone"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="phone" />
                  </div>
                </Form>
                <Form class="err-message">
                  <Field
                    v-model="organization_name"
                    type="text"
                    :placeholder="$t('placeholder.company')"
                    name="organization"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="organization" />
                  </div>
                </Form>
                <textarea
                  v-model="guest_desc"
                  :placeholder="$t('placeholder.com')"
                ></textarea>
              </div>
              <div class="modal-btn">
                <button
                  @click.prevent="Send"
                  :disabled="disabled"
                  :class="{isDisabled : disabled}"
                  @click="buttonClicked"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  {{ $t("buttons.send") }}
                </button>
              </div>
              <div class="modal-rule">
                <input type="checkbox" id="check" v-model="checked" />
                <label for="check"
                  >{{ $t("modal.check1") }}
                  <a href="/condition">{{ $t("modal.check2") }}</a></label
                >
              </div>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-vacancy animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <team-slider class="m_none" />
    <team-mobile class="d_none" />
  </div>
  <interview-form />
  <div class="interview">
    <div class="container">
      <p class="interview-title" v-html="inter.first"></p>
      <p class="interview-sub" v-html="inter.second"></p>
      <p class="interview-sub" v-html="inter.third"></p>
      <div class="interview-btn">
        <button @click="isOpen = true, this.$refs.myForm.resetForm()">{{ $t("buttons.offer") }}</button>
      </div>
      <modal class="int-modal" :open="isOpen" @close="isOpen = !isOpen">
        <div class="modal-title">{{ $t("modal.vacancy") }}</div>
        <Form ref="myForm" @submit="submitFile">
        <div class="modal-inputs">
          <div class="err-message">
            <Field
              v-model="name"
              name="username"
              type="text"
              class="name"
              :placeholder="$t('placeholder.name')"
              :rules="requiredName"
              @keyup="onlyText()"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="username" />
            </div>
          </div>
          <div ref="form" class="err-message">
            <Field
              v-model="phone"
              type="text"
              :placeholder="$t('placeholder.phone')"
              v-mask="'+7(7##)-###-##-##'"
              name="phone"
              :rules="requiredNum"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="phone" />
            </div>
          </div>
          <div class="selected">{{ $t("placeholder.choose") }}</div>
          <select v-model="selected" class="modal-vacancy">
            <option
              v-for="v in vac"
              :key="v.id"
              :value="v"
              v-html="v.title"
            ></option>
          </select>
          <br />
        </div>
        <div class="modal-res">
          {{ $t("modal.res") }}
          <span><img src="../assets/images/resume.png" alt="" /></span>
          <input
            class="file"
            type="file"
            id="file"
            ref="file"
            @change="handleFileUpload()"
          />
          <p class="file-name" v-if="file">{{ $t("par.chooseFile") }}</p>
        </div>
        <div class="modal-btn">
          <button
            type="submit"
            :class="{isDisabled : disabled}"
            @click="buttonClicked"
          >
            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
            {{ $t("buttons.send") }}
          </button>
        </div>
        <div class="modal-rule">
          <input type="checkbox" id="check" v-model="checked" />
          <label for="check"
            >{{ $t("modal.check1") }}
            <a href="/condition">{{ $t("modal.check2") }}</a></label
          >
        </div>
      </Form>
      </modal>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "../components/aboutUsHeader.vue";
import interviewForm from "../components/interviewForm.vue";
import teamSlider from "../components/teamSlider.vue";
import teamMobile from "@/components/box/teamMobile.vue";
import vacancySlider from "../components/box/vacancySlider.vue";
import vacancySlider2 from "../components/box/vacancySlider2.vue";
import jobBack from "@/components/jobBack.vue";
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { mapState } from "vuex";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { useToast } from "vue-toastification";
import moment from "moment";
const toaster = useToast()
// import first from '../assets/images/job-img.png'
// import second from '../assets/images/present.png'
// import third from '../assets/images/kasko1.png'
// import fourth from '../assets/images/paket.png'
// import fifth from '../assets/images/people.png'
// import sixth from '../assets/images/kasko-img.png'
export default {
  components: {
    aboutUsHeader,
    teamSlider,
    teamMobile,
    interviewForm,
    modal,
    vacancySlider,
    vacancySlider2,
    jobBack,
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      name: null,
      loading: false,
      disabled: false,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      tabContent: null,
      selected: "Выберите один из вариантов",
      // selected: undefined,
      vac: null,
      inter: [],
      three: [],
      product_name: "Грузы",
      resp: [],
      tab: "",
      checked: false,
      organization_name: null,
      guest_desc: null,
      tabs: [],
      file: "",
      four: [],
      IsRemove: false,
      file_name: null,
    };
  },
  created() {
    axios
      .get("V1/vacancies")
      .then((response) => {
        this.vac = response.data.data;
        this.inter = response.data.info.fifth_block;
        this.three = response.data.info.third_block;
        this.four = response.data.info.fourth_block;
        this.tabs = response.data.info.third_block.body;
        // this.tabContent = response.data.info.third_block.body
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    onlyText() {
      this.name = this.name.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      setTimeout(() => (this.disabled = !true), 4000);
    },
    tabButton(t) {
      if (t == null) {
        // console.log("test", this.tabs[0]);
        this.tabs[0] = t;
      } else {
        this.tabContent = t;
        // console.log("tab", t);
      }
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(valueNum) {
      if (!valueNum || valueNum.length < 17) {
        this.disabled = !false
        return this.$t("placeholder.req");
      }
      this.disabled = false
      return true;
    },
    Send(values, {resetForm}) {
      console.log(values);
      const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
      this.form = {
        username: this.GuestName,
        form_name: "consultant",
        organization_name: this.organization_name,
        phone: this.GuestPhone,
        email: this.GuestEmail,
        description: this.guest_desc,
        product_name: this.product_name,
      };
      axios
        .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
            Authorization: "Basic " + auth,
          },
        })
        .then((res) => {
          if (res.data.status == true) {
            this.loading = !false;
            setTimeout(() => (this.loading = !true), 3000);
            toaster.success(this.$t('notification.success'), {
                  position: "top-right"
                });
            this.IsRemove = true;
            resetForm()
            setTimeout(()=> (this.isOpen = false),3200);
          } else {
            // console.log('asdasdasdasd',res.data)
            toaster.error(this.$t("notification.req"), {
                  position: "top-right"
                });
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(auth);
        });
    },
    submitFile(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        let formData = new FormData();
        // formData.append("vacancy_id", "1");
        formData.append("name", this.name);
        formData.append("phone", this.phone);
        formData.append("vacancy_id", this.selected.id);
        formData.append("file", this.file);
        axios
          .post("V1/response", formData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.file_name = res.data.data.file;
            if (res.status == 201) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 3000);
              toaster.success(
                this.$t("notification.successVacancy"),
                {
                  position: "top-right"
                }
              );
              this.IsRemove = true;
              this.name = "";
              this.phone = "";
              this.file = "";
              this.selected = "Выберите один из вариантов";
              this.$refs.file.value = null;
              this.checked = false;
              resetForm();
              setTimeout(()=> (this.isOpen = false),500);
            } else {
              // console.log('asdasdasdasd',res.data)
              toaster.error(
                this.$t("notification.req"),
                {
                  position: "top-right"
                }
              );
            }
          })
          .catch((err) => {
            console.log(err);
            // console.log(auth);
            toaster.error(
              this.$t("notification.req"),
              {
                position: "top-right"
              }
            );
          });
      }
       else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log("asdasd", this.$refs.file.files[0]);
    },
    format_date(value) {
      const lang = localStorage.getItem('lang');
      if(lang == 'kz') {
        moment.locale('kk');
      }
      else if(lang == 'ru') {
        moment.locale('ru')
      }
      if (value) {
        return moment(String(value)).format('LL');
      }
    },
  },
  computed: {
    ...mapState(["cdn"]),
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.name = "";
      this.phone = "";
      this.file = "";
      // this.selected.id = "";
      // this.vacancy_id = "";
      this.vac.title = null;
      this.selected = "Выберите один из вариантов";
      this.$refs.file.value = null;
      this.checked = false;
      this.IsRemove = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1440px) and (max-width: 2560px) {
    @content;
  }
}
.selected {
  @include fontStyle(Roboto, normal, 300, 14px, 14px, #959595);
  padding-top: 10px;
}
.int-modal {
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
}
button:hover {
  .spinner-border {
    color: #FFF !important;
  }
}
.container {
  padding-left: 0;
  padding-right: 0;
}
.vac {
  @include mobile() {
    background: #efefef;
    margin-bottom: -25px;
    padding-top: 1px;
  }
  @include tablet() {
    background: #efefef;
    margin-bottom: -27px;
    padding-top: 1px;
  }
  @include tablet-1024() {
    background: #efefef;
    margin-bottom: -100px;
    padding-top: 1px;
  }
}
.file {
  width: 290px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  opacity: 0;
  margin-top: -28px;
}
.middle {
  margin-top: 200px;
  &-line {
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    height: 1px;
    margin-bottom: -150px;
    position: relative;
    z-index: 3;
  }
  &-box {
    z-index: 2;
    background-color: #f6f6f6;
    padding: 100px 100px;
    border-radius: 15px;
    max-width: 1320px;
    width: 100%;
    &__title {
      @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      &-1 {
        text-align: center;
      }
      &-1,
      &-3 {
        max-width: 300px;
        flex-wrap: wrap;
        button {
          background: #f6f6f6;
          width: 190px;
          height: 60px;
          border: 1px solid #c4c4c4;
          border-radius: 15px;
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
          margin-top: 40px;
          &:hover {
            filter: drop-shadow(0px 4px 43px rgba(0, 0, 0, 0.15));
            background-color: #fff;
            border: none;
          }
        }
      }
      &-2 {
        text-align: center;
        height: 380px;
        img {
          max-width: 106px;
          max-height: 106px;
        }
        .par {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, transparent);
          background: linear-gradient(
            90deg,
            #ff143f 0%,
            #c600dd 49.97%,
            #1b27ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          padding-top: 20px;
          padding-bottom: 60px;
          text-align: center;
          margin: 0 auto;
          height: 180px;
          width: 330px;
        }
        .btn {
          width: 380px;
          height: 70px;
          background: #ffffff;
          border-radius: 15px;
          button {
            padding-top: 15px;
            background: #ffffff;
            border: none;
            @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
          }
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
            button {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #ffffff;
              background-clip: text;
            }
          }
        }
        .modal {
          &-inputs {
            padding-top: 20px;
            text-align: center;
            input {
              margin-top: 10px;
              width: clamp(200px, 80%, 389px);
              background: #efefef;
              border-radius: 5px;
              border: none;
              padding: 10px 0 10px 10px;
              @include fontStyle(Roboto, normal, 300, 12px, 14px, #959595);
            }
          }
          &-resume {
            margin-top: 10px;
            p {
              @include fontStyle(Roboto, normal, 300, 14px, 18px, #373737);
            }
          }
          &-btn {
            text-align: center;
            margin-top: 20px;
            button {
              width: clamp(80px, 40%, 152px);
              background: linear-gradient(
                90deg,
                #ff4466 0%,
                #be31ce 49.97%,
                #3641ff 100%
              );
              border-radius: 5px;
              border: none;
              @include fontStyle(Roboto, normal, 600, 12px, 15px, #ffffff);
              padding: 12px 20px;
            }
          }
          &-rule {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            padding: 10px;
            margin-bottom: 10px;
            label {
              @include fontStyle(Roboto, normal, 400, 12px, 14px, #959595);
              cursor: pointer;
              margin-bottom: 0;
              a {
                color: #bd02ff;
              }
            }
          }
          &-res {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            padding-bottom: 10px;
            #file {
              width: 290px;
              display: flex;
              justify-content: center;
              margin: 0 auto;
            }
          }
        }
      }
      &-3 {
        text-align: center;
      }
    }
  }
  @include mobile() {
    margin-top: 0;
    &-box {
      background-color: transparent;
      margin-top: 0px;
      padding: 10px 0 30px 0;
      &__title {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 20px;
      }
      &__bottom {
        display: none;
      }
    }
    &-line {
      display: none;
    }
  }
  @include tablet() {
    margin-top: 0;
    &-box {
      margin-top: 20px;
      padding: 10px;
      background: transparent;
      &__title {
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 20px;
      }
      &__bottom {
        display: none;
      }
    }
    &-line {
      display: none;
    }
  }
  @include tablet-1024() {
    // background: #f6f6f6;
    margin-top: 50px;
    &-line {
      display: none;
    }
    &-box {
      background: transparent;
      margin-top: -40px;
      padding: 50px;
      &__title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include desktop() {
    margin-top: 170px;
    &-line {
      margin-bottom: -80px;
      height: 1px;
    }
    &-box {
      margin-top: -85px;
      padding: 50px;
      &__bottom {
        &-2 {
          width: 40%;
          .btn {
            width: 280px;
            height: 60px;
            border-radius: 8px;
            button {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
      &__title {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
.vacancy {
  margin: 85px 0;
  &-slider2 {
    display: none;
    @include mobile() {
      display: block;
    }
    @include tablet() {
      display: block;
    }
    @include tablet-1024() {
      display: block;
    }
  }
  &-slider {
    display: none;
    @include mobile() {
      display: block;
    }
    @include tablet() {
      display: block;
    }
    @include tablet-1024() {
      display: block;
    }
  }
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-sub {
    @include fontStyle(Roboto, normal, 300, 18px, 23px, #373737);
    padding: 20px 0;
  }
  &-top,
  &-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__card {
      padding: 40px 0 40px 50px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border: 2px solid #d3d3d3;
      box-sizing: border-box;
      border-radius: 15px;
      max-width: 600px;
      width: 100%;
      &__left {
        h4 {
          @include fontStyle(Roboto, normal, 400, 24px, 33px, #373737);
        }
        p {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
        }
      }
      &__right {
        text-align: center;
        p {
          @include fontStyle(Roboto, normal, 300, 14px, 17px, #959595);
        }
        .btn {
          a {
            color: #8948b1;
            &:hover {
              color: #fff;
            }
          }
          button {
            margin-top: 20px;
            margin-right: 20px;
            width: 154px;
            height: 69px;
            border: none;
            background-color: #efefef;
            border-radius: 15px;
            @include fontStyle(Roboto, normal, 600, 18px, 25px, #8948b1);
            &:hover {
              background: linear-gradient(
                90deg,
                #ff4466 0%,
                #be31ce 49.97%,
                #3641ff 100%
              );
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  @include mobile() {
    margin: 25px 0;
    &-title {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
    &-top,
    &-bottom {
      display: none;
    }
    &-sub {
      display: none;
    }
  }
  @include tablet() {
    margin: 25px 0;
    &-title {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
    &-top,
    &-bottom {
      display: none;
    }
    &-sub {
      display: none;
    }
  }
  @include tablet-1024() {
    &-title {
      font-size: 22px;
      line-height: 24px;
    }
    &-sub {
      font-size: 15px;
      line-height: 18px;
      padding: 15px 0;
    }
    &-top,
    &-bottom {
      display: none;
    }
  }
  @include desktop() {
    &-title {
      font-size: 22px;
      line-height: 24px;
    }
    &-sub {
      font-size: 15px;
      line-height: 18px;
      padding: 15px 0;
    }
    &-top,
    &-bottom {
      justify-content: space-evenly;
      &__card {
        padding: 20px 0 20px 25px;
        max-width: 380px;
        width: 100%;
        &__left {
          h4 {
            font-size: 18px;
            line-height: 23px;
          }
          p {
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 0;
          }
        }
        &__right {
          .btn {
            button {
              border-radius: 5px;
              width: 125px;
              height: 50px;
              font-size: 13px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  @include desktop-max() {
    &-top,
    &-bottom {
      &__card {
        // max-width: 550px;
        width: 32%;
        padding: 30px 0 30px 30px;
      }
    }
  }
}
.interview {
  padding: 85px 0;
  background: #efefef;
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-sub {
    @include fontStyle(Roboto, normal, 300, 18px, 22px, #000000);
    padding: 20px 0;
  }
  &-btn {
    background: #ffffff;
    width: 240px;
    height: 70px;
    border-radius: 15px;
    button {
      background: #ffffff;
      border-radius: 15px;
      padding: 22px 35px;
      border: none;
      @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
    }
    &:hover {
      background: linear-gradient(
        90deg,
        #ff4466 0%,
        #be31ce 49.97%,
        #3641ff 100%
      );
      color: #ffffff;
      button {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        background-clip: text;
      }
    }
  }
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
  @include tablet-1024() {
    &-title {
      font-size: 20px;
      line-height: 23px;
    }
    &-sub {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @include desktop() {
    &-title {
      font-size: 22px;
      line-height: 24px;
    }
    &-sub {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
</style>
