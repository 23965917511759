<template>
  <div v-if="TransportMain">
    <head-bottom />
    <div class="propertyMain">
      <div class="container">
        <div class="property">
          <div class="property-cards">
            <div class="property-cards-left">
              <p
                class="property-cards-left__title"
                v-html="TransportMain[0].title"
              ></p>
              <p
                class="property-cards-left__sub"
                v-html="TransportMain[0].desc"
              ></p>
              <div class="property-cards-left__btn white_hover__btn">
                <a :href="TransportMain[0].link">
                  <button>{{ $t("buttons.lmore") }}</button>
                </a>
              </div>
            </div>
            <div class="property-cards-right">
              <!-- <img src="../assets/images/airplane1.png" alt=""> -->
              <img :src="cdn + TransportMain[0].icon" alt="" />
            </div>
          </div>
          <div class="property-cards">
            <div class="property-cards-left">
              <p
                class="property-cards-left__title"
                v-html="TransportMain[1].title"
              ></p>
              <p
                class="property-cards-left__sub"
                v-html="TransportMain[1].desc"
              ></p>
              <div class="property-cards-left__btn white_hover__btn">
                <a :href="TransportMain[1].link"
                  ><button>{{ $t("buttons.lmore") }}</button></a
                >
              </div>
            </div>
            <div class="property-cards-right">
              <img :src="cdn + TransportMain[1].icon" alt="" />
            </div>
          </div>
          <div class="property-cards">
            <div class="property-cards-left">
              <p
                class="property-cards-left__title"
                v-html="TransportMain[2].title"
              ></p>
              <p
                class="property-cards-left__sub"
                v-html="TransportMain[2].desc"
              ></p>
              <div class="property-cards-left__btn white_hover__btn">
                <a :href="TransportMain[2].link"
                  ><button>{{ $t("buttons.lmore") }}</button></a
                >
              </div>
            </div>
            <div class="property-cards-right">
              <img :src="cdn + TransportMain[2].icon" alt="" />
            </div>
          </div>
          <div class="property-cards">
            <div class="property-cards-left">
              <p
                class="property-cards-left__title"
                v-html="TransportMain[3].title"
              ></p>
              <div class="button-top">
                <div class="button-top__left white_hover__btn">
                  <a href="/kaskoMain"
                    ><button>{{ $t("buttons.kasko") }}</button></a
                  >
                </div>
                <div class="button-top__right white_hover__btn">
                  <a href="/autoLimitTransport"
                    ><button>{{ $t("buttons.limit") }}</button></a
                  >
                </div>
              </div>
              <div class="button-bottom">
                <div class="button-bottom__left white_hover__btn">
                  <a href="/saktaTransport"
                    ><button>{{ $t("buttons.sakta") }}</button></a
                  >
                </div>
                <div class="button-bottom__right white_hover__btn">
                  <a href="/autoTransport"
                    ><button>{{ $t("buttons.pledged") }}</button></a
                  >
                </div>
              </div>
            </div>
            <div class="property-cards-right">
              <img :src="cdn + TransportMain[3].icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadBottom from "../components/HeadBottom.vue";
import airTransport from "../views/airTransport.vue";
import trainTransport from "../views/trainTransport.vue";
import boatTransport from "../views/boatTransport.vue";
import autoTransport from "../views/autoTransport.vue";
import saktaTransport from "../views/saktaTransport.vue";
import autoLimitTransport from "../views/autoLimitTransport.vue";
import kaskoMain from "../views/kaskoMain.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: {
    HeadBottom,
    airTransport,
    trainTransport,
    boatTransport,
    autoTransport,
    saktaTransport,
    autoLimitTransport,
    kaskoMain,
  },
  name: "TransportMain",
  data() {
    return {
      TransportMain: [],
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/business/transport")
      .then((response) => {
        this.TransportMain = response.data.data;
        //  console.log(response)
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}

@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}

@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -600px 600px;
  }
}

.propertyMain {
  background: #efefef;
  width: 100%;
  // background-image: url('../assets/images/cool-background1.png');
  background-image: url("../assets/images/back2.png");
  animation: animatedBackground 60s linear infinite;
  -moz-animation: animatedBackground 60s linear infinite;
  -webkit-animation: animatedBackground 60s linear infinite;
  -o-animation: animatedBackground 60s linear infinite;
}

.property {
  padding-top: 100px;
  padding-bottom: 80px;

  &-cards {
    border-radius: 15px;
    margin-bottom: 20px;
    background: #ffffff;
    width: 100%;
    padding: 32px 30px 17px 92px;
    display: flex;
    justify-content: space-between;
    &:hover {
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.2);
    }
    &:nth-child(1) {
      .property-cards-right {
        img {
          width: 100%;
        }
      }
    }
    &-left {
      &__title {
        @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
        text-transform: uppercase;
      }

      &__sub {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        padding: 10px 0;
      }

      &__btn {
        button {
          width: 215px;
          height: 69px;
          background: #efefef;
        }
      }
    }

    &-right {
      img {
        width: 85%;
        min-width: 300px;
      }
    }

    .button {
      &-top {
        display: flex;
        margin-top: 15px;
        margin-bottom: 20px;

        &__left {
          margin-right: 25px;

          button {
            width: 139px;
            height: 70px;
          }
        }

        &__right {
          button {
            width: 271px;
            height: 70px;
          }
        }

        &__left,
        &__right {
          button {
            // border-radius: 15px;
            // border: none;
            // @include fontStyle(Roboto, normal, 600, 18px, 25px,#FFFFFF);
            // background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
            // &:hover {
            //     border: 3px solid #BE31CE;
            // }
          }
        }
      }

      &-bottom {
        display: flex;

        &__left {
          margin-right: 25px;

          button {
            width: 140px;
            height: 70px;
          }
        }

        &__right {
          button {
            width: 179px;
            height: 70px;
          }
        }

        &__left,
        &__right {
          button {
            // border-radius: 15px;
            // border: none;
            // @include fontStyle(Roboto, normal, 600, 18px, 25px,#FFFFFF);
            // background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
            // &:hover {
            // border: 3px solid #BE31CE;
            // }
          }
        }
      }
    }
  }

  @include mobile() {
    padding-top: 30px;
    padding-bottom: 20px;

    &-cards {
      display: flex;
      flex-wrap: wrap;
      padding: 30px 20px;

      &-left {
        margin: 0 auto;

        &__title {
          font-size: 14px;
          line-height: 18px;
          text-align: center;
        }

        &__sub {
          text-align: center;
          font-size: 15px;
          line-height: 18px;
          padding: 15px 0 15px 0;
        }

        &__btn {
          text-align: center;
          margin: 0 auto;
          button {
            font-size: 12px;
            width: 135px;
            height: 35px;
            border-radius: 5px !important;
          }
        }
        .button-top {
          display: flex;
          justify-content: center;
          align-items: end;
          &__left,
          &__right {
            button {
              width: 115px;
              height: 35px;
              border-radius: 8px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .button-bottom {
          display: flex;
          justify-content: center;
          align-items: self-end;
          &__left,
          &__right {
            button {
              width: 115px;
              height: 35px;
              border-radius: 8px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }

      &-right {
        margin-top: 20px;
        text-align: center;

        img {
          min-width: 200px;
        }
      }
    }
  }

  @include tablet() {
    padding-top: 30px;
    padding-bottom: 20px;

    &-cards {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;

      &-left {
        margin: 0 auto;

        &__title {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          margin-top: 20px;
        }

        &__sub {
          text-align: center;
          font-size: 15px;
          line-height: 18px;
          padding: 15px 0 15px 0;
        }

        &__btn {
          text-align: center;
          margin: 0 auto;
          button {
            font-size: 12px;
            width: 150px;
            height: 40px;
            border-radius: 5px;
          }
        }

        .button-top {
          display: flex;
          justify-content: center;

          &__left,
          &__right {
            button {
              width: 135px;
              height: 40px;
              border-radius: 5px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .button-bottom {
          display: flex;
          justify-content: center;

          &__left,
          &__right {
            button {
              width: 135px;
              height: 40px;
              border-radius: 5px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }

      &-right {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
          min-width: 200px;
        }
      }
    }
  }

  @include tablet-1024() {
    padding-top: 20px;
    padding-bottom: 20px;

    &-cards {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;

      &-left {
        margin: 0 auto;

        &__title {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          margin-top: 20px;
        }

        &__sub {
          text-align: center;
          font-size: 18px;
          line-height: 20px;
          padding: 15px 0 15px 0;
        }

        &__btn {
          margin-bottom: 20px;
          text-align: center;
          margin: 0 auto;
          button {
            font-size: 12px;
            width: 150px;
            height: 40px;
            border-radius: 5px;
          }
        }

        .button-top {
          display: flex;
          justify-content: center;

          &__left,
          &__right {
            button {
              width: 100px;
              height: 35px;
              border-radius: 5px;
              font-size: 8px;
            }
          }
        }

        .button-bottom {
          display: flex;
          justify-content: center;

          &__left,
          &__right {
            button {
              width: 100px;
              height: 35px;
              border-radius: 5px;
              font-size: 8px;
            }
          }
        }
      }

      &-right {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        img {
          min-width: 200px;
        }
      }
    }
  }

  @include desktop() {
    &-cards {
      padding: 40px 50px;
      justify-content: space-between;
      &-left {
        width: 60%;
        &__title {
          font-size: 28px;
          line-height: 30px;
        }
        &__sub {
          font-size: 14px;
          line-height: 18px;
          padding: 15px 0 15px 0;
        }
        &__btn {
          button {
            width: 150px;
            height: 50px;
            border-radius: 5px;
            font-size: 12px;
          }
        }
        .button-top,
        .button-bottom {
          &__left {
            button {
              width: 120px;
              height: 40px;
              border-radius: 8px;
              font-size: 12px;
            }
          }

          &__right {
            button {
              width: 170px;
              height: 40px;
              border-radius: 8px;
              font-size: 12px;
            }
          }
        }
      }
      &-right {
        width: 33%;
        height: 170px;
        img {
          height: 160px;
          width: 260px;
          object-fit: contain;
        }
      }
    }
  }

  @include desktop-max() {
    &-cards {
      padding: 55px 70px;
      margin-bottom: 63px;

      &-left {
        width: 59%;
      }
      &-right {
        display: flex;
        justify-content: end;
        img {
          width: 101%;
          min-width: 300px;
          height: 250px;
        }
      }
    }
  }
}
</style>
