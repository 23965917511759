<template>
  <about-us-header class="animate__animated animate__fadeIn" style="animation-duration: 3s;" />
  <div class="event animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="container">
      <div class="events">
        <div class="event-left">
          <p class="event-title" v-html="event[0].title"></p>
          <div class="event-buttons">
            <div class="event-btn">
              <button @click="isOpen = true, this.$refs.myForm.resetForm()">
                {{ $t("buttons.sponsor") }}
              </button>
            </div>
            <div class="event-btn">
              <button @click="isOpenSpeaker = true, this.$refs.myForm2.resetForm()">
                {{ $t("buttons.speaker") }}
              </button>
            </div>
            <modal :open="isOpen" @close="isOpen = !isOpen">
              <h3 class="modal-title">{{ $t("buttons.sponsor") }}</h3>
              <Form ref="myForm" @submit="Send">
              <div class="modal-inputs">
                <!-- <input v-model="GuestName" type="text" :placeholder="$t('placeholder.name')" /> -->
                <div class="err-message">
                  <Field
                    v-model="GuestName"
                    name="name"
                    type="text"
                    class="name"
                    :placeholder="$t('placeholder.name')"
                    :rules="requiredName"
                    @keyup="onlyText()"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="GuestPhone"
                    type="text"
                    :placeholder="$t('placeholder.phone')"
                    v-mask="'+7(7##)-###-##-##'"
                    name="phone"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="phone" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="GuestEmail"
                    type="email"
                    :rules="validateEmail"
                    name="email"
                    id=""
                    :placeholder="$t('placeholder.email')"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="organization_name"
                    type="text"
                    :placeholder="$t('placeholder.company')"
                    name="organization"
                    class="name"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="organization" />
                  </div>
                </div>
                <textarea
                v-model="comment"
                :placeholder="$t('placeholder.com')"
              ></textarea>
              </div>
              <div class="modal-btn">
                <button
                  type="submit"
                  :class="{isDisabled : disabled}"
                  @click="buttonClicked"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  {{ $t("buttons.send") }}
                </button>
              </div>
              <div class="modal-rule">
                <input type="checkbox" id="check" v-model="checked" />
                <label for="check"
                  >{{ $t("modal.check1") }}
                  <a href="/condition">{{ $t("modal.check2") }}</a></label
                >
              </div>
            </Form>
            </modal>
            <modal
              :open="isOpenSpeaker"
              @close="isOpenSpeaker = !isOpenSpeaker"
            >
              <h3 class="modal-title">{{ $t("buttons.speaker") }}</h3>
              <Form ref="myForm2" @submit="SendSpeaker">
              <div class="modal-inputs">
                <div class="err-message">
                  <Field
                    v-model="GuestName"
                    name="name"
                    type="text"
                    class="name"
                    :placeholder="$t('placeholder.name')"
                    :rules="requiredName"
                    @keyup="onlyText()"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="GuestPhone"
                    type="text"
                    :placeholder="$t('placeholder.phone')"
                    v-mask="'+7(7##)-###-##-##'"
                    name="phone"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="phone" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="GuestEmail"
                    type="email"
                    :rules="validateEmail"
                    name="email"
                    id=""
                    :placeholder="$t('placeholder.email')"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="organization_name"
                    type="text"
                    :placeholder="$t('placeholder.company')"
                    name="organization"
                    class="name"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="organization" />
                  </div>
                </div>
                <textarea
                v-model="comment"
                :placeholder="$t('placeholder.com')"
              ></textarea>
              </div>
              <div class="modal-btn">
                <button
                  type="submit"
                  :class="{isDisabled : disabled}"
                  @click="buttonClicked"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  {{ $t("buttons.send") }}
                </button>
              </div>
              <div class="modal-rule">
                <input type="checkbox" id="check" v-model="checked" />
                <label for="check"
                  >{{ $t("modal.check1") }}
                  <a href="/condition">{{ $t("modal.check2") }}</a></label
                >
              </div>
            </Form>
            </modal>
          </div>
        </div>
        <div class="event-right">
          <img :src="cdn + event[0].icon" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="container animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="channel">
      <img :src="cdn + event[1].icon" alt="" />
      <div class="channel-text">
        <h5 class="channel__title" v-html="event[1].title"></h5>
        <p class="channel-par" v-html="event[1].description"></p>
      </div>
    </div>
    <div class="bot d_none">
      <div class="bot-top">
        <img src="@/assets/images/bot.svg" alt="" />
        <a href="https://t.me/HelpCentras_bot"
          >Подпишитесь на наш <br />
          Telegram Бот</a
        >
      </div>
      <div class="bot-par">
        Холдинг Centras, частью которого является и страховая <br />
        компания «Сентрас Иншуранс», является инициатором и <br />
        организатором более 10 профильных мероприятий <br />
        ежегодно. Все мероприятия проходят под эгидой <br />
        корпоративного фонда Kazakhstan Growth Forum, <br />
        образованного в 2011 году. Вся актуальная информация <br />о
        мероприятиях и регистрация доступны на сайте
        <a href="https://k50.kz/">k50.kz</a>
      </div>
    </div>
  </div>
  <div class="news">
    <div class="container">
      <div class="news-card" v-for="news in event.slice(2, 19)" :key="news">
        <div class="text">
          <h3 v-html="news.title"></h3>
        </div>
        <div class="box">
          <img class="img" :src="cdn + news.icon" alt="" />
          <div class="news-card__par">
            <p class="news-card__par__title" v-html="news.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "../components/aboutUsHeader.vue";
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { mapState } from "vuex";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { useToast } from "vue-toastification";
const toaster = useToast()
export default {
  components: { aboutUsHeader, modal, Field, Form, ErrorMessage },
  data() {
    return {
      disabled: false,
      loading: false,
      GuestName: null,
      GuestPhone: null,
      comment: null,
      GuestEmail: null,
      organization_name: null,
      guest_desc: null,
      checked: false,
      event: null,
      IsRemove: false,
    };
  },
  created() {
    axios
      .get("V1/events")
      .then((response) => {
        this.event = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      setTimeout(() => (this.disabled = !true), 5000);
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      return true;
    },
    async Send(values, { resetForm }) {
      console.log(values);
      if (this.checked === true) {
        this.form = {
          name: this.GuestName,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          organization_name: this.organization_name,
          comment: this.comment,
        };
        await axios
          .post("V1/become-sponsor", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
            },
          })
          .then((res) => {
            if (res.status === 200) {
              toaster.success(this.$t("notification.send"), {
                  position: "top-right"
                });
              // this.GuestName = "";
              // this.GuestPhone = "";
              // this.GuestEmail = "";
              // this.comment = "";
              // this.guest_desc = "";
              // this.organization_name = "";
              this.checked = false;
              this.IsRemove = true;
              resetForm();
              setTimeout(()=> (this.isOpen = false),500);
            } else {
              alert(this.$t('notification.req'))
              toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
            }
          })
          .catch((err) => {
            toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
            console.log(err);
          });
              // console.log( document.querySelector('.resetButton').click());
      } else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
      }
      this.loading = !false;
      setTimeout(() => (this.loading = !true), 3000);
    },
    async SendSpeaker(values, { resetForm }) {
      console.log(values);
      if (this.checked === true) {
        this.form = {
          name: this.GuestName,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          organization_name: this.organization_name,
          comment: this.comment
        };
        axios
          .post("V1/become-speaker", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
            },
          })
          .then((res) => {
            if (res.status === 200) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 3000);
              toaster.success(this.$t("notification.send"), {
                  position: "top-right"
                });
              // this.GuestName = "";
              // this.GuestPhone = "";
              // this.GuestEmail = "";
              // this.comment = "";
              // this.organization_name = "";
              this.checked = false;
              this.IsRemove = true;
              resetForm();
              setTimeout(()=> (this.isOpenSpeaker = false), 500);
            } else {
              toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
            }
          })
          .catch((err) => {
            toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
      }
    },
  },
  setup() {
    const isOpen = ref(false);
    const isOpenSpeaker = ref(false);
    return { isOpen, isOpenSpeaker };
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestPhone = "";
      this.GuestEmail = "";
      this.comment = "";
      this.organization_name = "";
      this.checked = false;
      this.IsRemove = true;
      this.loading = false;
      // setTimeout(function () {
      //   location.reload(true)
      // }, 1500);
    },
    isOpenSpeaker: function () {
      if (this.isOpenSpeaker) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestPhone = "";
      this.GuestEmail = "";
      this.comment = "";
      this.organization_name = "";
      this.checked = false;
      this.IsRemove = true;
      this.loading = false;
      // setTimeout(function () {
      //   location.reload(true)
      // }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.bot {
  display: none;
  &-top {
    display: flex;
    align-items: center;
    margin-top: 24px;
    img {
      width: 53%;
    }
    a {
      text-decoration: underline !important;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 42.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      @include fontStyle(Roboto, normal, 300, 10px, 12px, #373737);
      margin-left: 14px;
      padding-bottom: 5px;
    }
  }
  &-par {
    padding-top: 14px;
    padding-bottom: 30px;
    @include fontStyle(Roboto, normal, 300, 10px, 12px, #373737);
    margin: 0 auto;
    a {
      font-weight: 600;
    }
  }
}
// .remove {
//   display: none;
//   margin-bottom: -15px;
// }
.events {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event {
  background: #efefef;
  padding: 75px 0;
  &-title {
    @include fontStyle(Roboto, normal, 500, 42px, 58px, transparent);
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-bottom: 30px;
  }
  &-btn {
  }
  &-buttons {
    display: flex;
    .modal {
      &-title {
        @include fontStyle(Roboto, normal, 400px, 16px, 20px, #373737);
        text-align: center;
        padding-top: 40px;
      }
      &-inputs {
        padding-top: 20px;
        text-align: center;
        input {
          margin-top: 10px;
          width: clamp(200px, 80%, 389px);
          background: #efefef;
          border-radius: 5px;
          border: none;
          padding: 10px 0 10px 10px;
          @include fontStyle(Roboto, normal, 300, 12px, 14px, #959595);
        }
      }
      &-btn {
        text-align: center;
        margin-top: 20px;
        button {
          width: clamp(80px, 40%, 152px);
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          border-radius: 5px;
          border: none;
          @include fontStyle(Roboto, normal, 600, 12px, 15px, #ffffff);
          padding: 12px 20px;
        }
      }
      &-rule {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;
        label {
          @include fontStyle(Roboto, normal, 400, 12px, 14px, #959595);
          cursor: pointer;
          margin-bottom: 0;
          a {
            color: #bd02ff;
          }
        }
      }
      @include mobile() {
        &-title {
          padding-top: 10px;
        }
        &-btn {
          button {
            padding: 10px;
          }
        }
        &-rule {
          label {
            font-size: 9px;
          }
        }
        &-inputs {
          padding-top: 0;
        }
      }
      @include tablet() {
        &-title {
          padding-top: 10px;
        }
        &-btn {
          button {
            padding: 10px;
          }
        }
        &-rule {
          label {
            font-size: 9px;
          }
        }
        &-inputs {
          padding-top: 0;
        }
      }
    }
  }
  &-btn {
    width: 220px;
    height: 70px;
    background: #ffffff;
    border-radius: 15px;
    margin-right: 40px;
    button {
      width: 220px;
      height: 70px;
      background: #ffffff;
      border-radius: 15px;
      border: none;
      color: #8948b1;
      @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
    }
    &:hover {
      background: linear-gradient(
        90deg,
        #ff4466 0%,
        #be31ce 49.97%,
        #3641ff 100%
      );
      color: #ffffff;
      button {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        background-clip: text;
      }
    }
  }
  @include mobile() {
    padding: 25px 0;
    &-left {
      width: 60%;
    }
    &-right {
      width: 30%;
      img {
        width: 119%;
      }
    }
    &-title {
      width: 109%;
      @include fontStyle(Roboto, normal, 500, 14px, 16px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      background: #373737;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 0;
    }
    &-btn {
      width: 120px;
      height: 34px;
      border-radius: 3px;
      margin-right: 15px;
      button {
        width: 120px;
        height: 34px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  @include tablet() {
    padding: 25px 0;
    &-left {
      width: 60%;
      margin-right: 2%;
    }
    &-right {
      img {
        width: 100%;
      }
    }
    &-title {
      @include fontStyle(Roboto, normal, 500, 16px, 18px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      background: #373737;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &-btn {
      min-width: 110px;
      max-width: 115px;
      height: 34px;
      border-radius: 3px;
      margin-right: 15px;
      button {
        min-width: 110px;
        max-width: 115px;
        height: 34px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  @include tablet-1024() {
    &-left {
      width: 70%;
    }
    &-right {
      img {
        width: 100%;
      }
    }
    &-title {
      font-size: 22px;
      line-height: 25px;
    }
  }
}
.channel {
  display: flex;
  padding: 50px 0;
  align-items: center;
  img {
    width: 18%;
    margin-right: 40px;
  }
  &-text {
    width: 60%;
  }
  &-par {
    @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
    span {
      font-weight: 600;
    }
    a {
      text-decoration: underline;
      font-weight: 500;
    }
  }
  &__title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  @include mobile() {
    padding: 30px 0 20px 0;
    &__title {
      font-size: 14px;
      line-height: 16px;
    }
    &-par {
      font-size: 14px;
      line-height: 16px;
    }
    img {
      width: 36%;
      margin-right: 20px;
    }
  }
  @include tablet() {
    padding: 30px 0 20px 0;
    &__title {
      font-size: 14px;
      line-height: 16px;
    }
    &-par {
      font-size: 14px;
      line-height: 16px;
    }
    img {
      width: 36%;
      margin-right: 20px;
    }
  }
  @include tablet-1024() {
    &__title {
      font-size: 15px;
      line-height: 18px;
      text-decoration: underline;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-par {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.news {
  background: #efefef;
  padding-bottom: 50px;
  padding-top: 70px;
  &-title {
    padding: 50px 0 70px 0;
    @include fontStyle(Roboto, normal, 600, 24px, 29px, #373737);
  }
  &-card {
    background: #ffffff;
    margin-bottom: 60px;
    padding: 40px;
    .text,
    .box {
      display: flex;
      justify-content: space-between;
    }
    .text {
      align-items: center;
      h3 {
        @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }
    &__par {
      padding-left: 30px;
      &__date {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #959595);
      }
      &__title,
      &__sub,
      &__link,
      h4 {
        @include fontStyle(Roboto, normal, 400, 18px, 22px, #373737);
      }
      &__title,
      &__sub {
        a {
          font-weight: 700;
        }
      }
      &__link {
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      h4 {
        a {
          text-decoration: underline;
          font-weight: 600;
        }
        span {
          font-weight: 700;
        }
      }
    }
    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    }
  }
  @include mobile() {
    padding-top: 33px;
    padding-bottom: 1px;
    &-card {
      margin-bottom: 30px;
      padding: 20px;
      .text {
        h3 {
          font-size: 16px;
          line-height: 17px;
          background: linear-gradient(
            90deg,
            #ff143f 0%,
            #c600dd 49.97%,
            #1b27ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          width: 84%;
        }
      }
      .box {
        flex-wrap: wrap;
        .img {
          width: 80%;
          max-height: 140px;
          border-radius: 3px;
        }
      }
      &__par {
        padding-left: 0px;
        padding-top: 20px;
        &__title,
        &__sub,
        &__link,
        h4 {
          font-size: 14px;
          line-height: 16px;
        }
        &__date {
          display: none;
        }
        &__link {
          display: none;
        }
      }
    }
  }
  @include tablet() {
    padding-bottom: 1px;
    padding-top: 35px;
    &-card {
      margin-bottom: 35px;
      padding: 20px;
      .text {
        h3 {
          font-size: 16px;
          line-height: 17px;
          background: linear-gradient(
            90deg,
            #ff143f 0%,
            #c600dd 49.97%,
            #1b27ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          width: 84%;
        }
      }
      .box {
        flex-wrap: wrap;
        .img {
          width: 80%;
          height: 170px;
          border-radius: 3px;
        }
      }
      &__par {
        padding-left: 0px;
        padding-top: 20px;
        &__title,
        &__sub,
        h4 {
          font-size: 14px;
          line-height: 16px;
        }
        &__date {
          display: none;
        }
        &__link {
          display: none;
        }
      }
    }
  }
  @include tablet-1024() {
    &-card {
      .text {
        h3 {
          font-size: 14px;
          line-height: 17px;
        }
      }
      .box {
        .img {
          width: 35%;
          height: 160px;
        }
      }
      &__par {
        padding-left: 20px;
        &__title,
        &__sub,
        &__link,
        h4 {
          font-size: 10px;
          line-height: 12px;
        }
        &__date {
          font-size: 10px;
          line-height: 12px;
        }
        &__link {
          display: none;
        }
      }
    }
  }
  @include desktop() {
    &-card {
      .text {
        h3 {
          font-size: 22px;
          line-height: 23px;
        }
      }
      .box {
        .img {
          max-height: 180px;
          width: 283px;
        }
      }
      &__par {
        width: 70%;
        &__title,
        &__sub,
        &__link,
        h4 {
          font-size: 14px;
          line-height: 17px;
        }
        &__date {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
  @include desktop-max() {
    &-card {
      .box {
        .img {
          width: 371px;
          height: 200px;
        }
      }
      &__par {
        width: 70%;
      }
    }
  }
}
</style>
