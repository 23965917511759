<template>
  <div class="discount">
    <div class="container">
      <h2 class="discount-title m_none">{{ disc.title }}</h2>
      <h2 class="discount-title-mobile d_none" v-html="disc.title">
      </h2>
      <h5 class="discount-sub">
        <p>{{ disc.description_1 }}</p>
        <span><img src="../assets/images/Line2.png" alt="" /></span>
        <p>{{ disc.description_2 }}</p>
      </h5>
      <div class="discount-inp">
        <div class="discount-inp__name">
          <Form class="err-message">
            <Field
              @click="IsRemove = false"
              v-model="GuestName"
              name="username"
              type="text"
              class="name"
              :placeholder="$t('placeholder.name')"
              :disabled="inputDisable"
              :rules="requiredName"
              @keyup="onlyText()"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="username" />
            </div>
          </Form>
        </div>
        <div class="discount-inp__mail">
          <Form class="err-message" ref="form">
            <Field
              @click="IsRemove = false"
              v-model="organization_name"
              type="text"
              :rules="requiredName"
              name="organization"
              id=""
              :placeholder="$t('placeholder.company')"
              :disabled="inputDisable"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="organization" />
            </div>
          </Form>
        </div>
        <div class="discount-inp__tel">
          <Form class="err-message">
            <Field
              @click="IsRemove = false"
              v-mask="'+7(7##)-###-##-##'"
              v-model="GuestPhone"
              v-validate="'min:9'"
              type="text"
              name="phone"
              class="tel"
              :placeholder="$t('placeholder.phone')"
              :disabled="inputDisable"
              :rules="requiredNum"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="phone" />
            </div>
          </Form>
        </div>
        <!-- <textarea class="textarea" name="" placeholder="Комментарии"></textarea> -->
      </div>
      <!-- <textarea class="textarea" name="" placeholder="Комментарии"></textarea> -->
      <div class="discount-bottom">
        <div class="discount-btn white_hover__btn">
          <button
            @click.prevent="Send"
            :disabled="disabled"
            :class="{isDisabled : disabled}"
            @click="buttonClicked"
          >
            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
            <h2>{{ disc.button }}</h2>
            <!-- <h2>Получить скидку <span>20%</span></h2> -->
          </button>
        </div>
        <div class="rule">
          <p class="discount-par">{{ disc.description_3 }}</p>
          <div class="discount-check">
            <input type="checkbox" id="check" v-model="checked" />
            <label for="check"
              >{{ $t("modal.rule1") }}
              <a href="/condition"
                >{{ $t("modal.rule2") }}</a
              ></label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { mapState } from "vuex";
import { Field, Form, ErrorMessage } from "vee-validate";
// import {required} from "@vee-validate/rules";
// import { required, email } from 'vuelidate/lib/validators'
import { Buffer, resolveObjectURL } from "buffer";
import { useToast } from 'vue-toastification'
const toaster = useToast();
// import {TheMask} from 'vue-the-mask'
export default {
  props: ["p_name"],
  components: {  Field, Form, ErrorMessage },

  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      disc: [],
      loading: false,
      disabled: false,
      GuestName: null,
      GuestPhone: "",
      organization_name: null,
      resp: [],
      checked: false,
      IsRemove: false,
      inputDisable: false,
      hexTokens: {
        Y: { pattern: /[0-9]/ },
        "#": { pattern: /\d/ },
        X: { pattern: /[0-9a-zA-Z]/ },
        // 'S': {pattern: /[А-Яа-я]/},
        S: { pattern: /[a-zA-Z]/ },
        A: { pattern: /[a-zA-Z0-9]/, transform: (v) => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
        "!": { escape: true },
        F: {
          pattern: /[0-9ЁёА-я]/,
        },
        // 'N': {pattern: /[a-zA-ZА-Яа-я]/},
      },
    };
  },
  methods: {
    buttonClicked() {
      this.disabled = !false;
      setTimeout(() => (this.disabled = !true), 6500);
    },
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      //All is good
      return true;
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    Send() {
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "discount",
          phone: this.GuestPhone,
          organization_name: this.organization_name,
          description: "mainPage",
          product_name: this.p_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.isOpen = false;
              toaster.success(this.$t('notification.success'), {
                position: "top-right"
              });
              this.GuestName = "";
              this.organization_name = "";
              this.GuestPhone = "";
              this.checked = false,
              this.IsRemove = true;
            } else {
              toaster.error(this.$t('notification.req'), {
                position: "top-right"
              });
            }
          })
          .catch((err) => {
            // console.log(err);
            // console.log(auth);
          });
          this.loading = !false;
          setTimeout(() => (this.loading = !true), 3000);
      } else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
      }
    },
  },
  created() {
    axios
      .get("V1/discount-request")
      .then((response) => {
        this.disc = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 426px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 426px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-mid {
  @media (min-width: 1440px) and (max-width: 1620px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
button:hover {
  .spinner-border {
    color: #fff;
  }
}
.isDisabled {
  opacity: 0.2;
  backdrop-filter: sepia(90%);
}
.err-message {
  display: flex;
  flex-direction: column;
  span {
    @include fontStyle(Roboto, normal, 400, 16px, 20px, red);
    padding-top: 5px;
  }
  @include tablet-1024() {
    span {
      font-size: 8px;
      line-height: 12px;
    }
  }
  @include desktop() {
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.remove {
  display: none;
  margin-bottom: -15px;
}
.image_mobilka {
  display: none;
  @include mobile() {
    display: flex;
    justify-content: flex-end;
    margin-top: -130px;
    img {
      top: 0;
      z-index: 100;
      width: 158px;
    }
  }
  @include tablet() {
    display: flex;
    justify-content: flex-end;
    margin-top: -130px;
    img {
      top: 0;
      z-index: 100;
    }
  }
}
.container {
  @include mobile() {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
  @include tablet() {
    width: 80%;
    margin: 0 auto;
    padding: 0;
  }
}
.discount {
  padding: 114px 0;
  background: #efefef;
  background-image: url(../assets/images/girl_parcent.png);
  background-repeat: no-repeat;
  // background-position-x: right;
  background-position-y: 90px;
  background-size: 100%;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 49px, #373737);
    // text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 35px;
    span {
      display: none;
    }
  }
  &-sub {
    margin-bottom: 35px;
    @include fontStyle(Roboto, normal, 300, 20px, 26px, #959595);
    // text-align: center;
    text-transform: uppercase;
    display: flex;
    // justify-content: center;
    span {
      img {
        margin: 0 16px 4px 16px;
      }
    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    .rule {
      margin-left: 35px;
    }
  }
  .modal {
    &-inputs {
      padding-top: 20px;
      text-align: center;
      input {
        margin-top: 10px;
        width: clamp(200px, 80%, 389px);
        background: #efefef;
        border-radius: 5px;
        border: none;
        padding: 10px 0 10px 10px;
        @include fontStyle(Roboto, normal, 300, 12px, 14px, #959595);
      }
    }
    &-btn {
      text-align: center;
      margin-top: 20px;
      button {
        width: clamp(95px, 40%, 152px);
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        border-radius: 5px;
        border: none;
        @include fontStyle(Roboto, normal, 600, 12px, 15px, #ffffff);
        padding: 12px 20px;
      }
    }
    &-rule {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      margin-bottom: 10px;
      label {
        @include fontStyle(Roboto, normal, 400, 12px, 14px, #959595);
        cursor: pointer;
        margin-bottom: 0;
        a {
          color: #bd02ff;
        }
      }
    }
  }
  &-inp {
    display: flex;
    justify-content: center;
    &__name,
    &__mail,
    &__tel {
      input {
        width: 250px;
        height: 70px;
        border: none;
        background: #ffffff;
        // backdrop-filter: blur(15px);
        border-radius: 15px;
        @include fontStyle(Roboto, normal, 400, 18px, 29px, #373737);
        padding-left: 20px;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      margin-right: 35px;
    }
  }

  &-btn {
    display: flex;
    justify-content: center;
    // margin-top: 35px;
    // margin-bottom: 30px;
    margin: 35px 0;
    button {
      background: #ffffff;
      width: 304px;
      height: 70px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        @include mobile() {
          font-size: 9px;
        }
        @include tablet() {
          font-size: 10px;
        }
        @include tablet-1024() {
          font-size: 10px;
        }
        @include desktop() {
          font-size: 14px;
        }
      }
    }
  }
  &-par {
    @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
    text-align: center;
    margin-bottom: 0;
  }
  &-check {
    @include fontStyle(Roboto, normal, 300, 14px, 18px, #959595);
    align-items: center;
    display: flex;
    // justify-content: center;
    padding-top: 12px;
    padding-bottom: 0px;
    label {
      margin-left: 5px;
      margin-bottom: 0px;
      a {
        text-decoration: underline;
        color: #959595;
      }
    }
    input {
      width: 17px;
      height: 17px;
      margin-bottom: 2px;
      accent-color: #4a94ff;
    }
  }
  @include mobile() {
    //discount
    padding: 40px 0;
    // padding-bottom: 30px;
    background-image: none;
    background-color: transparent;
    &-bottom {
      flex-direction: column-reverse;
      align-items: stretch;
      .rule {
        margin-left: 0;
      }
    }
    &-title-mobile {
      text-align: left;
      font-family: Roboto;
      font-weight: 600;
      margin: 0 auto;
      font-size: 14px;
      line-height: 16px;
      background: linear-gradient(
        90deg,
        #ff143f 6%,
        #c600dd 33.97%,
        #1b27ff 65%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      span {
        display: inline-block;
      }
    }
    &-sub {
      display: none;
    }
    &-inp {
      flex-wrap: wrap;
      flex-direction: column;
      // min-width: 320px;
      width: 100%;
      justify-content: flex-start;
      &__mail {
        // display: none;
      }
      &__name,
      &__tel,
      &__mail {
        margin-top: 10px;
        input {
          // min-width: 280px;
          // width: 100%;
          width: clamp(280px, 111%, 400px);
          height: 45px;
          border: 1px solid #efefef;
          border-radius: 3px;
          margin-right: 0;
          //changes
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    &-btn {
      max-width: 400px;
      text-align: left;
      border-radius: 3px;
      margin: 12px 0 0 0px;
      // padding-top: 12px;
      button {
        width: clamp(280px, 111%, 400px);
        height: 45px;
        background-color: #efefef;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
        h2 {
          font-size: 14px;
          line-height: 14px;
        }
        span {
          display: none;
        }
      }
    }
    &-par {
      display: none;
      padding-top: 10px;
      //changes
      font-size: 12px;
      line-height: 14px;
    }
    &-check {
      font-size: 10px;
      line-height: 12px;
      width: 83%;
    }
  }
  @include tablet() {
    padding: 40px 0;
    background-image: none;
    background-color: transparent;
    &-bottom {
      flex-direction: column-reverse;
      align-items: stretch;
      .rule {
        margin-left: 0;
      }
    }
    &-title-mobile {
      text-align: left;
      font-family: Roboto;
      font-weight: 600;
      margin: 0 auto;
      font-size: 14px;
      line-height: 16px;
      background: linear-gradient(
        90deg,
        #ff143f 6%,
        #c600dd 33.97%,
        #1b27ff 65%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      span {
        display: inline-block;
      }
    }
    &-sub {
      display: none;
    }
    &-inp {
      flex-wrap: wrap;
      flex-direction: column;
      // min-width: 320px;
      width: 100%;
      justify-content: flex-start;
      &__mail {
        // display: none;
      }
      &__name,
      &__tel,
      &__mail {
        margin-top: 10px;
        input {
          // min-width: 280px;
          // width: 100%;
          width: clamp(280px, 111%, 400px);
          height: 45px;
          border: 1px solid #efefef;
          border-radius: 3px;
          margin-right: 0;
          //changes
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    &-btn {
      max-width: 700px;
      text-align: left;
      border-radius: 3px;
      margin: 12px 0 0 0px;
      // padding-top: 12px;
      button {
        // width: clamp(280px,111%,400px);
        width: 100%;
        height: 45px;
        background-color: #efefef;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
        h2 {
          font-size: 14px;
          line-height: 14px;
        }
        span {
          display: none;
        }
      }
    }
    &-par {
      display: none;
      padding-top: 10px;
      //changes
      font-size: 12px;
      line-height: 14px;
    }
    &-check {
      font-size: 10px;
      line-height: 12px;
      width: 83%;
    }
  }
  @include tablet-1024() {
    background-color: #efefef;
    padding-top: 70px;
    padding-bottom: 10px;
    background-position-y: 102%;
    &-sub {
      display: none;
    }
    &-title {
      font-size: 20px;
      line-height: 23px;
    }
    &-inp {
      display: flex;
      justify-content: start;
      &__name,
      &__tel,
      &__mail {
        input {
          width: 120px;
          height: 40px;
          border-radius: 7px;
          font-size: 10px;
          line-height: 12px;
          border: 1px solid #efefef;
        }
      }
    }
    &-btn {
      border-radius: 8px;
      // margin: 25px auto;
      button {
        width: 130px;
        height: 40px;
        border-radius: 8px;
        font-size: 10px;
        line-height: 12px;
      }
    }
    &-par {
      font-size: 9px;
      line-height: 12px;
    }
    &-check {
      font-size: 8px;
      line-height: 10px;
      padding-top: 0;
      padding-bottom: 0;
      label {
        margin-left: 5px;
      }
      input {
        width: 10px;
      }
    }
  }
  @include desktop() {
    padding-top: 55px;
    padding-bottom: 25px;
    background-position-y: 100%;
    &-title {
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 20px;
    }
    &-sub {
      font-size: 15px;
      line-height: 16px;
    }
    &-inp {
      justify-content: start;
      &__name,
      &__mail,
      &__tel {
        input {
          width: 180px;
          height: 50px;
          border-radius: 10px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    &-btn {
      border-radius: 10px;
      button {
        width: 180px;
        height: 50px;
        border-radius: 10px;
        font-size: 14px;
        line-height: 16px;
      }
    }
    &-par {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 0;
    }
    &-check {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 10px;
      line-height: 12px;
      label {
        margin-left: 5px;
      }
      input {
        width: 14px;
      }
    }
  }
  @include desktop-mid() {
    background-position-y: 103%;
    background-size: 106%;
    &-inp {
      justify-content: start;
    }
  }
  @include desktop-max() {
    background-position-y: 109%;
    &-inp {
      justify-content: start;
      &__name,
      &__mail,
      &__tel {
        input {
          width: 304px;
        }
      }
    }
  }
}
</style>
