<template>
  <div>
    <heasdad-bottom />
    <div class="gpo">
      <div class="container">
        <p class="gpo-title" v-html="gpo.title"></p>
        <p class="gpo-sub">
          {{ $t('product.gpoText') }}
        </p>
        <div class="gpo-card">
          <div class="gpo-left">
            <p class="gpo-left__par" v-html="gpo.description"></p>
            <div class="gpo-left__box">
              <div class="gpo-left__box__rec"></div>
              <div class="gpo-left__box__rec"></div>
              <div class="gpo-left__box__rec"></div>
              <div class="gpo-left__box__rec"></div>
              <div class="gpo-left__box__rec"></div>
            </div>
            <div class="gpo-left__btn">
              <button
                @click="isOpen = true, this.$refs.myForm.resetForm()"
                id="click7"
                v-html="gpo.button"
              ></button>
              <!-- <button @click="isOpen=true" >Получить подробную информацию</button> -->
            </div>
            <modal :open="isOpen" @close="isOpen = !isOpen">
              <get-discount />
              <Form ref="myForm" @submit="Send">
              <div class="modal-inputs">
                <div class="err-message">
                  <Field
                    v-model="GuestName"
                    name="username"
                    type="text"
                    class="name"
                    :placeholder="$t('placeholder.name')"
                    :rules="requiredName"
                    @keyup="onlyText()"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="username" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-mask="'+7(7##)-###-##-##'"
                    v-model="GuestPhone"
                    type="text"
                    :placeholder="$t('placeholder.phone')"
                    name="phone"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="phone" />
                  </div>
                </div>
                <div class="err-message">
                  <Field
                    v-model="organization_name"
                    type="text"
                    :placeholder="$t('placeholder.company')"
                    name="organization"
                    class="name"
                    :rules="requiredNum"
                  />
                  <div :class="{ remove: IsRemove }" class="">
                    <ErrorMessage name="organization" />
                  </div>
                </div>
                <textarea
                  v-model="guest_desc"
                  :placeholder="$t('placeholder.com')"
                ></textarea>
              </div>
              <div class="modal-btn">
                <button
                  type="submit"
                  :class="{isDisabled : disabled}"
                  @click="buttonClicked"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  {{ $t("buttons.send") }}
                </button>
              </div>
              <div class="modal-rule">
                <input type="checkbox" id="check" v-model="checked" />
                <label for="check"
                  >{{ $t("modal.check1") }}
                  <a href="/condition">{{ $t("modal.check2") }}</a></label
                >
              </div>
            </Form>
            </modal>
          </div>
          <div class="gpo-right">
            <div
              class="gpo-right__box"
              v-html="gpo.mini_description_first"
            ></div>
            <div
              class="gpo-right__box"
              v-html="gpo.mini_description_second"
            ></div>
            <div
              class="gpo-right__box"
              v-html="gpo.mini_description_third"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <hr class="middle-line" />
      <div class="container">
        <div class="middle-box">
          <p class="middle-box__title" v-html="tabs[0].big_title"></p>
          <!-- <p class="middle-box__title" v-html="gpo2[0].big_title"></p> -->
          <div class="gpo-slider">
            <gpo-slider />
          </div>
          <div class="middle-box__bottom">
            <div class="middle-box__bottom-1">
              <button
                v-for="t in tabs.slice(0, 3)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>

            <div class="middle-box__bottom-3">
              <button
                v-for="t in tabs.slice(3, 6)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>
            <div class="middle-box__bottom-2" v-if="tabContent">
              <img :src="cdn + tabContent.icon" alt="" />
              <div class="par" v-html="tabContent.description"></div>
              <div class="btn">
                <button
                  @click="isOpen = true, this.$refs.myForm.resetForm()"
                  id="click7"
                  v-html="tabContent.button"
                ></button>
                <!-- <button @click="isOpen=true" >Получить подробную информацию</button> -->
              </div>
              </div>
            <div class="middle-box__bottom-2" v-else>
              <img :src="cdn + tabs[0].icon" alt="" />
              <div class="par" v-html="tabs[0].description"></div>
              <div class="btn">
                <button @click="isOpen = true, this.$refs.myForm.resetForm()" v-html="tabs[0].button"></button>
                <!-- <button @click="isOpen=true" >Получить подробную информацию</button> -->
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="about-gpo">
        <p class="about-gpo__title" v-html="gpo3[0].big_title"></p>
        <div class="about-gpo__card" v-for="(g, i) in gpo3" :key="g">
          <p class="about-gpo__card__num" v-if="i < 10">0{{ i + 1 }}.</p>
          <p class="about-gpo__card__num" v-else>{{ i + 1 }}.</p>
          <div class="about-gpo__card__par">
            <span v-html="g.title"></span>
            <p v-html="g.description"></p>
          </div>
        </div>
        <!-- <div class="about-gpo__card">
                    <p class="about-gpo__card__num">02.</p>
                    <div class="about-gpo__card__par"><span v-html="gpo3[1].title"></span><p v-html="gpo3[1].description"></p></div>
                </div> -->
      </div>
    </div>
    <div class="range">
      <range />
    </div>
    <div class="sliderRange">
      <slider-range />
    </div>
    <div class="reverse1">
      <accordion />
      <discount :p_name="p_name" />
    </div>
    <SliderMain1 />
    <!-- <div class="slide-btm">
            <div class="container">
                <p class="slide-btm__title">Что говорят наши клиенты</p>
            </div>
        </div> -->
    <client-say />
    <div class="wrap">
      <slider-main-2 />
      <div class="d_none">
        <review-slide />
      </div>
    </div>
  </div>
</template>

<script>
import HeadBottom from "../components/HeadBottom.vue";
import accordion from "../components/accordion.vue";
import Discount from "../components/Discount.vue";
import SliderMain1 from "../components/SliderMain1-1.vue";
import SliderMain2 from "../components/SliderMain2.vue";
import range from "../components/range.vue";
import sliderRange from "../components/sliderRange.vue";
import gpoSlider from "../components/box/gpoSlider.vue";
import reviewSlide from "../components/box/reviewSlide.vue";
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { mapState } from "vuex";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { useToast } from "vue-toastification";
const toaster = useToast()
// import first from '../assets/images/gpo-img.png'
// import second from '../assets/images/kasko-img.png'
// import third from '../assets/images/gpo3.png'
// import fourth from '../assets/images/gpo4.png'
// import fifth from '../assets/images/gpo5.png'
// import sixth from '../assets/images/kasko6.png'
import "@/assets/style/main.scss";
import getDiscount from "@/components/getDiscount.vue";
import clientSay from "@/components/clientSay.vue";

export default {
  components: {
    HeadBottom,
    accordion,
    Discount,
    SliderMain1,
    SliderMain2,
    modal,
    range,
    sliderRange,
    gpoSlider,
    reviewSlide,
    getDiscount,
    clientSay,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      loading: false,
      disabled: false,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      tabContent: null,
      p_name: "Гражданско-правовая ответственность перед третьими лицами",
      product_name: "Гражданско-правовая ответственность перед третьими лицами",
      resp: [],
      checked: false,
      tab: "",
      organization_name: null,
      guest_desc: null,
      tabs: [],
      gpo: [],
      gpo2: [],
      gpo3: [],
      IsRemove: false,
    };
  },
  created() {
    axios
      .get("V1/top-products")
      .then((response) => {
        this.gpo = response.data.data[6].first_block;
        this.tabs = response.data.data[6].second_block;
        this.gpo3 = response.data.data[6].third_block;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-.\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    tabButton(t) {
      if (t == null) {
        // console.log("test", this.tabs[0]);
        this.tabs[0] = t;
      } else {
        this.tabContent = t;
        // console.log("tab", t);
      }
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "consultant",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          description: this.guest_desc,
          product_name: this.product_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 20000);
              toaster.success(this.$t('notification.success'), {
                  position: "top-right"
                });
              resetForm()
              setTimeout(() => {
                this.isOpen = false;
              }, 500);
            } else {
              toaster.error(this.$t('notification.req'), {
                  position: "top-right"
                });
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree'),
          {
            position: "top-right"
          }
        );
      }
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.checked = false;
      this.IsRemove = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background: #ffffff;
}
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 426px) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1023px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.wrap {
  padding-top: 15px;
}
.gpo {
  background: url(../assets/images/gpo-back1.png);
  background-repeat: no-repeat;
  padding-bottom: 120px;
  &-slider {
    display: none;
    @include mobile() {
      display: block;
    }
    @include tablet() {
      display: block;
    }
    @include tablet-1024() {
      display: block;
    }
  }
  &-card {
    display: flex;
    justify-content: space-between;
  }
  &-title {
    @include fontStyle(Roboto, normal, 400, 32px, 46px, #ffffff);
    text-transform: uppercase;
    padding-top: 50px;
    margin-bottom: 0px;
    p {
      margin-bottom: 0;
    }
  }
  &-sub {
    @include fontStyle(Roboto, normal, 400, 22px, 26px, #ffffff);
    text-transform: uppercase;
    padding-bottom: 26px;
  }
  &-left {
    &__par {
      @include fontStyle(Roboto, normal, 400, 20px, 23px, #373737);
      letter-spacing: 0.01em;
      text-shadow: 0px 4px 50px rgba(0, 0, 0, 0.45);
      padding-bottom: 18px;
      p {
        margin-bottom: 0;
      }
    }
    &__box {
      display: flex;
      &__rec {
        margin-right: 15px;
        margin-bottom: 35px;
        width: 36px;
        height: 185px;
        border-radius: 3px;
        // border: 1px solid #FFFFFF;
      }
    }
    &__btn {
      // width: 405px;
      // height: 70px;
      // background: #FFFFFF;
      border-radius: 15px;
      margin-top: 50px;
      margin-bottom: 120px;
      button {
        width: 405px;
        height: 70px;
        background: #ffffff;
        border-radius: 15px;
        border: none;
        @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
        p {
          margin-bottom: 0 !important;
        }
        margin-bottom: 0 !important;
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #ffffff;
          button {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            background-clip: text;
          }
        }
      }
    }
  }
  &-right {
    &__box {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(30px);
      border-radius: 15px;
      width: 405px;
      height: 98px;
      padding: 37px;
      @include fontStyle(Roboto, normal, 300, 17px, 23px, #373737);
      margin-bottom: 30px;
    }
  }
  &-btn {
    width: 405px;
    height: 70px;
    background: #ffffff;
    border-radius: 15px;
    margin-top: 50px;
    margin-bottom: 120px;
    button {
      width: 405px;
      height: 70px;
      background: #ffffff;
      border-radius: 15px;
      border: none;
      @include fontStyle(Roboto, normal, 500, 18px, 25px, transparent);
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &:hover {
      background: linear-gradient(
        90deg,
        #ff4466 0%,
        #be31ce 49.97%,
        #3641ff 100%
      );
      color: #ffffff;
      button {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        background-clip: text;
      }
    }
  }
  @include mobile() {
    background-image: url("../images/gpo-back22.png");
    padding-bottom: 50px;
    background-size: cover;
    background-color: #efefef;
    height: 370px;
    &-title {
      font-size: 16px;
      line-height: 20px;
      padding-top: 35px;
      padding-bottom: 15px;
    }
    &-sub {
      //change
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 10px;
      margin-bottom: 0;
      text-transform: none;
      color: #373737;
    }
    &-left {
      width: 100%;
      &__box {
        display: none;
      }
      &__par {
        display: none;
      }
      &__btn {
        height: 0px;
        border-radius: 8px;
        margin-top: 29%;
        button {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          left: 14px;
          margin-top: 32px;
        }
      }
    }
    &-right {
      display: none;
    }
  }
  @include tablet() {
    background-image: url("../images/gpo-back22.png");
    padding-bottom: 20px;
    background-size: cover;
    background-color: #efefef;
    &-title {
      font-size: 18px;
      line-height: 22px;
      padding-top: 35px;
      padding-bottom: 15px;
    }
    &-sub {
      //change
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 10px;
      margin-bottom: 0;
      text-transform: none;
      color: #373737;
    }
    &-left {
      width: 100%;
      &__box {
        display: none;
      }
      &__par {
        display: none;
      }
      &__btn {
        height: 0px;
        border-radius: 8px;
        margin-top: 29%;
        button {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          left: 14px;
          margin-top: 55px;
        }
      }
    }
    &-right {
      display: none;
    }
  }
  @include tablet-1024() {
    background-size: cover;
    padding-bottom: 0;
    background-color: #efefef;
    &-title {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
      padding-top: 10px;
    }
    &-sub {
      font-size: 12px;
      line-height: 15px;
    }
    &-left {
      &__par,
      &__box {
        display: none;
      }
      &__btn {
        width: 300px;
        height: 60px;
        border-radius: 8px;
        margin-top: 10px;
        margin-bottom: 20px;
        button {
          width: 300px;
          height: 60px;
          border-radius: 8px;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    &-right {
      margin-top: -40px;
      &__box {
        width: 165px;
        height: 48px;
        padding: 10px 20px;
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 15px;
        border-radius: 8px;
      }
    }
  }
  @include desktop() {
    &-left {
      &__box {
        // display: none;
        height: 150px;
      }
      &__btn {
        width: 350px;
        button {
          width: 350px;
          font-size: 16px;
        }
      }
    }
  }
}
.middle {
  &-line {
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    height: 1px;
    // margin-top: -170px;
    position: relative;
    z-index: 3;
  }
  &-box {
    z-index: 2;
    background-color: #f6f6f6;
    padding: 100px 100px;
    border-radius: 15px;
    // margin-top: -180px;
    max-width: 1320px;
    width: 100%;
    &__title {
      @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      &-1 {
        width: 250px;
        height: 80px;
        text-align: center;
      }
      &-1,
      &-3 {
        max-width: 200px;
        flex-wrap: wrap;
        button {
          width: 190px;
          height: 60px;
          border: 1px solid #c4c4c4;
          border-radius: 15px;
          background: #f6f6f6;
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
          margin-top: 40px;
          &:hover {
            filter: drop-shadow(0px 4px 43px rgba(0, 0, 0, 0.15));
            background-color: #fff;
            border: none;
          }
        }
      }
      &-2 {
        text-align: center;
        height: 380px;
        img {
          max-width: 106px;
          max-height: 106px;
        }
        .par {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, transparent);
          background: #373737;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          padding-top: 20px;
          padding-bottom: 60px;
          text-align: center;
          margin: 0 auto;
          height: 180px;
          width: 330px;
        }
        .btn {
          width: 380px;
          height: 70px;
          background: #ffffff;
          border-radius: 15px;
          button {
            padding-top: 15px;
            background: #ffffff;
            // background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // background-clip: text;
            border: none;
            @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
          }
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
            button {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #ffffff;
              background-clip: text;
            }
          }
        }
      }
      &-3 {
        text-align: center;
      }
    }
  }
  @include mobile() {
    padding-top: 20px;
    padding-bottom: 25px;
    background: #efefef;
    background-size: cover;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0;
      background: transparent;
      padding: 0;
      &__title {
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 10px;
        padding-top: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet() {
    background: #efefef;
    background-size: cover;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0;
      background: transparent;
      padding: 0;
      &__title {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 10px;
        padding-top: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet-1024() {
    background: #f6f6f6;
    &-line {
      display: none;
    }
    &-box {
      // margin-top: -10px;
      padding: 30px;
      &__title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }
      &__bottom {
        display: none;
        &-2 {
          width: 100%;
          padding-left: 20px;
          img {
            width: 80px;
            height: 80px;
          }
          .par {
            font-size: 13px;
            line-height: 18px;
          }
          .btn {
            width: 150px;
            height: 50px;
            background: #ffffff;
            border-radius: 8px;
            button {
              font-size: 9px;
              line-height: 12px;
              padding-top: 0;
            }
          }
        }
      }
    }
  }
  @include desktop() {
    &-line {
      margin-top: -45px;
      height: 1px;
    }
    &-box {
      margin-top: -150px;
      padding: 50px;
      &__title {
        font-size: 22px;
        line-height: 26px;
      }
      &__bottom {
        &-2 {
          width: 100%;
          padding-left: 10px;
          .btn {
            width: 280px;
            height: 60px;
            border-radius: 10px;
            button {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  @include desktop-max() {
    &-box {
      margin-top: -220px;
    }
  }
}
.about {
  margin: 0 auto;
  max-width: 1320px;
  width: 80%;
  &-gpo {
    margin-top: 95px;
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 40px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 10px;
    }
    &__card {
      display: flex;
      align-items: center;
      &__num {
        @include fontStyle(Roboto, normal, 900, 60px, 82px, transparent);
        background: -webkit-linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        color: #ffffff;
        align-items: center;
      }
      &__par {
        width: 1220px;
        height: 70px;
        display: flex;
        align-items: baseline;
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        background: #f6f6f6;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-sizing: border-box;
        backdrop-filter: blur(55px);
        border-radius: 15px;
        padding: 20px;
        margin-left: 12px;
        span {
          @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
          padding-right: 20px;
        }
      }
    }
  }
  @include mobile() {
    width: 90%;
    &-gpo {
      margin-top: 35px;
      margin-bottom: -5px;
      &__title {
        //change
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        line-height: 14px;
        background: linear-gradient(
          90deg,
          #ff143f 14%,
          #c600dd 23.97%,
          #1b27ff 29%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
        margin-bottom: 0;
      }
      &__card {
        align-items: baseline;
        margin-bottom: 0px;
        &__num {
          font-size: 14px;
          line-height: 15px;
          padding: 1px;
        }
        &__par {
          background-color: transparent;
          padding: 5px;
          margin-left: 0;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-wrap: wrap;
          span {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            width: 100%;
          }
        }
      }
    }
  }
  @include tablet() {
    &-gpo {
      margin-top: 45px;
      &__title {
        font-size: 18px;
        line-height: 22px;
        background: linear-gradient(
          90deg,
          #ff143f 14%,
          #c600dd 23.97%,
          #1b27ff 29%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
      }
      &__card {
        align-items: baseline;
        margin-bottom: 30px;
        &__num {
          font-size: 15px;
          line-height: 18px;
        }
        &__par {
          background-color: transparent;
          padding: 5px;
          margin-left: 0;
          font-size: 13px;
          line-height: 17px;
          display: flex;
          flex-wrap: wrap;
          span {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            width: 100%;
          }
        }
      }
    }
  }
  @include tablet-1024() {
    &-gpo {
      margin-top: 50px;
      &__card {
        &__num {
          font-size: 40px;
          line-height: 48px;
        }
        &__par {
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 20px;
          span {
            font-size: 20px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
